import { captureException } from '@sentry/vue'
import { Flashcard, FlashcardType, FlashcardDifficulty } from '../../types/Flashcard.js'

const DAY = 1440 // 24 * 60 minutes

export function getNewCardState(card: Flashcard, option: FlashcardDifficulty) {
	if (card.type === 'new' || card.type === 'learning') {
		return handleLearningResponse(card, option)
	} else if (card.type === 'review') {
		return handleReviewingResponse(card, option)
	} else if (card.type === 'lapsed') {
		return handleRelearningResponse(card, option)
	}
}

export function handleLearningResponse(card: Flashcard, option: FlashcardDifficulty): Flashcard {
	const newCardState = card
	delete newCardState._modified

	switch (option) {
		case 'again':
			newCardState.interval = 1
			break
		case 'hard':
			newCardState.interval = 6
			newCardState.learning_step = 1
			newCardState.type = 'learning'
			break
		case 'good':
			newCardState.interval = card.learning_step === 0 ? 10 : DAY
			newCardState.type = card.learning_step === 0 ? 'learning' : 'review'
			newCardState.learning_step = 1
			break
		case 'easy':
			newCardState.interval = 4 * DAY
			newCardState.type = 'review'
			newCardState.learning_step = 1
			break
	}

	const now = new Date()
	const nextReview = new Date()
	nextReview.setMinutes(now.getMinutes() + Math.floor(newCardState.interval))
	newCardState.next_review = nextReview.toISOString()
	if (!newCardState.type && card.type === 'new') newCardState.type = 'learning'
	newCardState.interval = Math.floor(newCardState.interval)
	return newCardState
}

export function handleReviewingResponse(card: Flashcard, option: FlashcardDifficulty): Flashcard {
	const newCardState = card
	delete newCardState._modified

	switch (option) {
		case 'again':
			newCardState.type = 'lapsed'
			newCardState.interval = 10
			newCardState.easiness = Math.max(card.easiness - 0.2, 1.3)
			break
		case 'hard':
			newCardState.interval = card.interval * 1.2
			newCardState.easiness = Math.max(card.easiness - 0.15, 1.3)
			break
		case 'good':
			newCardState.interval = card.interval * card.easiness
			break
		case 'easy':
			newCardState.interval = card.interval * card.easiness * 1.5
			newCardState.easiness = Math.min(card.easiness + 0.15, 2.5)
			break
	}

	const now = new Date()
	const nextReview = new Date()
	nextReview.setMinutes(now.getMinutes() + Math.floor(newCardState.interval))
	newCardState.next_review = nextReview.toISOString()
	newCardState.interval = Math.floor(newCardState.interval)
	return newCardState
}

export function handleRelearningResponse(card: Flashcard, option: FlashcardDifficulty): Flashcard {
	const newCardState = card
	delete newCardState._modified

	switch (option) {
		case 'again':
			newCardState.interval = 1
			break
		case 'hard':
			newCardState.interval = 6
			break
		case 'good':
			newCardState.interval = DAY
			newCardState.type = 'review'
			break
		case 'easy':
			newCardState.interval = DAY * 4
			newCardState.type = 'review'
			break
	}
	const now = new Date()
	const nextReview = new Date()
	nextReview.setMinutes(now.getMinutes() + Math.floor(newCardState.interval))
	newCardState.next_review = nextReview.toISOString()
	newCardState.interval = Math.floor(newCardState.interval)
	return newCardState
}

export function convertIntervalToTimeString(minutes: number) {
	if (minutes < 60) {
		return `< ${minutes}m`
	} else if (minutes < 1440) {
		const hours = Math.floor(minutes / 60)
		return `< ${hours}h`
	} else if (minutes < 43800) {
		const days = Math.floor(minutes / (24 * 60))
		return `< ${days}d`
	} else if (minutes < 525600) {
		const months = Math.floor(minutes / (24 * 60 * 30))
		return `< ${months}mon`
	} else {
		const years = Math.floor(minutes / (24 * 60 * 30 * 12))
		return `< ${years}yr`
	}
}

// [again hard good easy]
export function calculateRemainingTime(card: Flashcard) {
	const retArray = []
	switch (card.type) {
		case 'new':
			if (card.learning_step == 0) {
				retArray.push(convertIntervalToTimeString(1))
				retArray.push(convertIntervalToTimeString(6))
				retArray.push(convertIntervalToTimeString(10))
				retArray.push(convertIntervalToTimeString(5760))
				return retArray
			}
			retArray.push(convertIntervalToTimeString(1))
			retArray.push(convertIntervalToTimeString(6))
			retArray.push(convertIntervalToTimeString(1440))
			retArray.push(convertIntervalToTimeString(5760))
			return retArray

		case 'learning':
			if (card.learning_step == 0) {
				retArray.push(convertIntervalToTimeString(1))
				retArray.push(convertIntervalToTimeString(6))
				retArray.push(convertIntervalToTimeString(10))
				retArray.push(convertIntervalToTimeString(5760))
				return retArray
			}
			retArray.push(convertIntervalToTimeString(1))
			retArray.push(convertIntervalToTimeString(6))
			retArray.push(convertIntervalToTimeString(1440))
			retArray.push(convertIntervalToTimeString(5760))
			return retArray

		case 'review':
			retArray.push(convertIntervalToTimeString(10))
			retArray.push(convertIntervalToTimeString(card.interval * 1.2))
			retArray.push(convertIntervalToTimeString(card.interval * card.easiness))
			retArray.push(convertIntervalToTimeString(card.interval * card.easiness * 1.5))
			return retArray

		case 'lapsed':
			retArray.push(convertIntervalToTimeString(1))
			retArray.push(convertIntervalToTimeString(6))
			retArray.push(convertIntervalToTimeString(1440))
			retArray.push(convertIntervalToTimeString(5760))
			return retArray

		default: {
			const errorMessage = `Invalid card type: ${card.type}`
			console.error(errorMessage)
			captureException(new Error(errorMessage))
		}
	}
}
