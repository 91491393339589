<template>
	<div v-on:click="closeReferral" class="popup-background-open" id="referral">
		<div class="popup standard_text" v-on:click.stop style="min-width: 360px">
			<div style="display: flex; justify-content: flex-end; align-items: flex-end">
				<button @click="closeReferral">
					<i class="bi bi-x fa-3x" style="font-size: 3rem"></i>
				</button>
			</div>
			<img src="../../assets/people.svg" alt="Image" class="people" />
			<h2 class="title" style="font-weight: 700; color: #4a5361">
				{{ $t('message.referral.inviteFriend') }}
			</h2>
			<p class="description">
				{{ $t('message.referral.description') }}
			</p>
			<div class="link-container">
				<div class="url">
					<p class="link-input">{{ apiBase }}{{ inviteUri }}</p>
				</div>
				<div class="divider"></div>
				<div class="action">
					<button v-if="windowSizeStore.isDesktop" @click.stop="copyLink">
						<span v-if="!copied" class="url-span">
							<img src="../../assets/link.svg" style="text-align: center" />
							{{ $t('message.referral.copyLink') }}
						</span>
						<span v-else class="url-span">
							{{ $t('message.referral.linkCopied') }}
						</span>
					</button>
					<button v-else @click.stop="copyLink">
						<span v-if="!copied" class="url-span-mobile">
							<img src="../../assets/link.svg" style="text-align: center" />
						</span>
						<span v-else class="url-span-mobile">
							{{ $t('message.referral.copied') }}
						</span>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, computed } from 'vue'
import { useWindowSizeStore, useUserStore } from '@/store/store.js'
import { captureException } from "@sentry/vue";

const appUrl = import.meta.env.VITE_VUE_APP_URL

const windowSizeStore = useWindowSizeStore()
const userStore = useUserStore()

const apiBase = ref(`${appUrl}/signup?referral=`)
const inviteUri = ref('')
const copied = ref(false)

function copyLink() {
	navigator.clipboard
		.writeText(`${apiBase.value}${inviteUri.value}`)
		.then(() => {
			copied.value = true
		})
		.catch((err) => {
			console.error('Error in copying text: ', err)
			captureException(err);
		})
}

function closeReferral() {
	windowSizeStore.toggleReferral()
}

onMounted(() => {
	inviteUri.value = userStore.user.invite_uid
})
</script>

<style scoped>
.url-span {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.5rem;
	font-size: 16px;
	color: #7a219e;
}

.url-span-mobile {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.5rem;
	font-size: 12px;
	color: #7a219e;
}

.link-input {
	font-size: 16px;
	border-radius: 0px;
	word-wrap: break-word;
	margin: 0;
}

.url {
	width: 70%;
	padding: 1rem;
}

.action {
	display: grid;
	place-items: center;
}

.divider {
	background-color: #f1e8f4;
	width: 1px;
}

.link-container {
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	border: 1px solid #f1e8f4;
	border-radius: 8px;
}

.link-button {
	float: right;
	margin-left: 20px;
	margin-right: 0;
	border-left: 1px solid #ccc;
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>
