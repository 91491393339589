<script setup>
import { ref } from 'vue'
import UserInfo from './UserInfo.vue'
import { supabase } from '@/common/database'
import { router } from '@/router'
import { toast } from 'vue3-toastify'
import { useDeckStore } from '@/store/store'
import { useI18n } from 'vue-i18n'
import { useUserStore, useWindowSizeStore } from '../../store/store'

const showNavbar = ref(false)
const windowSizeStore = useWindowSizeStore()
const deckStore = useDeckStore()
const userStore = useUserStore()
const { t } = useI18n()

function toggleNavbar() {
	showNavbar.value = !showNavbar.value
}
function navigateHome() {
	router.push('/dashboard')
	showNavbar.value = false
}

function openReferral() {
	toggleNavbar()
	windowSizeStore.toggleReferral()
}

function openFeedback() {
	windowSizeStore.toggleFeedback()
	toggleNavbar()
}

function openSettings() {
	windowSizeStore.toggleSettings()
	toggleNavbar()
}

async function handleLogOut() {
	await userStore.signOut(router)
}

function checkNavigate(e) {
	if (e.target.tagName !== 'SPAN') {
		toggleNavbar()
		return
	}
	toggleNavbar()
	toast(t('message.leftNav.noDecksToast'))
}

</script>

<template>
	<div class="mobile-navbar" id="navbarMobile">
		<div class="container inner-container">
			<div class="container burger-container">
				<img src="../../assets/small-logo.svg" alt="Logo" style="width: 32px" @click="navigateHome" />
				<fa-icon icon="fa-solid fa-bars" class="icon" @click="toggleNavbar"></fa-icon>
			</div>
			<router-link to="/create" class="primaryButton new-cards-button">{{
				$t('message.leftNav.createNewFlashcards') }}</router-link>
		</div>
	</div>
	<div class="popup-background-open" @click="toggleNavbar" v-if="showNavbar">
		<div class="actual-navbar" @click.stop>
			<div class="top-container">
				<div class="container" style="justify-content: space-between; align-items: center">
					<img src="../../assets/logo.svg" alt="Logo" style="width: 45%" @click="navigateHome" />
					<fa-icon id="close-navbar" @click="toggleNavbar" icon="fa-sold fa-x"
						style="font-size: 16px; color: #6e7580"></fa-icon>
				</div>
				<UserInfo @open-settings="openSettings()" />
				<div class="buttons" style="flex-direction: column; margin-top: 16px">
					<router-link class="button" to="/dashboard" active-class="button-active" @click="toggleNavbar">
						<img src="../../assets/homeIcon.svg" />
						{{ $t('message.leftNav.homeButton') }}
					</router-link>
					<component :is="deckStore.decks.length != 0 ? 'router-link' : 'span'" to="/Decks" class="button"
						active-class="button-active" @click="checkNavigate">
						<img src="../../assets/decksIcon.svg" />
						{{ $t('message.leftNav.decksButton') }}
					</component>
					<router-link to="shop" class="button" active-class="button-active" @click="toggleNavbar()">
						<img src="../../assets/tokensIcon.svg" />
						{{ $t('message.leftNav.tokensButton') }}
					</router-link>
					<button class="button" @click="openFeedback">
						<img src="../../assets/feedbackIcon.svg" />
						{{ $t('message.leftNav.feedbackButton') }}
					</button>
				</div>
			</div>
			<div class="bottom-container">
				<div class="card">
					<div class="container">
						<div class="logo">
							<img src="../../assets/megaphoneImage.svg" alt="Image"
								style="width: 72px; height: auto; margin-bottom: 24px" />
						</div>
						<h1 class="standard_text" style="font-size: 14px; margin: 0; color: #6e7580; font-weight: 500">
							{{ $t('message.leftNav.inviteFriendText1') }} <b>{{ $t('message.leftNav.inviteFriendText2')
								}}</b>
						</h1>
					</div>
					<button href="#" class="primaryButton" style="padding: 7px, 24px; margin-bottom: 0; width: 100%"
						@click="openReferral">
						Invite a friend
					</button>
				</div>
				<button class="button" @click="handleLogOut">
					<img src="../../assets/logoutIcon.svg" />
					Log out
				</button>
			</div>
		</div>
	</div>
</template>

<style scoped>
.top-container {
	display: flex;
	flex-direction: column;
	gap: 2rem;
}
.bottom-container {
	display: flex;
	flex-direction: column;
	gap: 2rem;
}
.mobile-navbar {
	position: sticky;
	top: 0;
	width: 100%;
	z-index: 3;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.burger-container {
	padding: 0;
	margin: 0;
	align-items: center;
	gap: 8px;
}

.inner-container {
	justify-content: space-between;
	background-color: white;
	padding: 16px;
}

.icon {
	color: #4A5260;
	height: 24px;
	opacity: 0.6;
}

.actual-navbar {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: white;
	padding: 20px;
	z-index: 9;
	display: flex;
	max-width: 311px;
	flex-direction: column;
	justify-content: space-between;
}

.new-cards-button {
	font-size: 14px;
	padding: 8px 12px;
	font-weight: 700;
	margin: 0;
	width: max-content;
	white-space: nowrap;
	line-height: 1.43;
	height: max-content;
	width: max-content;
}

.buttons {
	display: flex;
	flex-direction: column;
	gap: 10px;
	margin-bottom: 1rem;
	width: 100%;
}
</style>
