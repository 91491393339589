<script setup>
import { ref, computed } from 'vue'
import { useUserStore, useWindowSizeStore, useTransactionStore } from '@/store/store'
import { useI18n } from 'vue-i18n'
import { supabase } from '@/common/database.js'
import { toast } from 'vue3-toastify'
import GenericPopup from '@/components/common/GenericPopup.vue'
import { v4 as uuidv4 } from 'uuid'
import { useRouter } from 'vue-router'
import SubscriptionTab from '@/components/settings/SubscriptionTab.vue'
import { captureException } from "@sentry/vue";
import { UserService } from '../../services/user.service'

const { t } = useI18n()
const { locale } = useI18n({ useScope: 'global' })
const router = useRouter()
const emit = defineEmits(['close-settings'])

const pages = ['account', 'tokens', 'subscription']

const transactionStore = useTransactionStore()
const windowSizeStore = useWindowSizeStore()
const userStore = useUserStore()

const currentPage = ref('account')
const showDeleteAccountPopup = ref(false)
const editName = ref(false)
const currentName = ref(userStore.user.name ? userStore.user.name :
	userStore.user.email.split('@')[0])
const reason = ref('')

const tokenBalance = computed(() => transactionStore.tokenBalance)

function closeSettings() {
	emit('close-settings')
}

async function changeLocale(newLocale) {
	if (locale.value === newLocale) return
	const response = await userStore.changeLocale(newLocale, locale)
	if (!response) return
	if (response.error) {
		toast.error(t('message.errorCodes.settings.localeChangeFailed'))
	}
}

function shouldShowTab(page) {
	if (page === 'tokens' && userStore.user.subscription_user) {
		return false
	}
	return true
}

function handleOpenDeleteAccountPopup() {
	showDeleteAccountPopup.value = true
}
async function handleDeleteAccount() {
	if (reason.value === '') {
		toast('Could you please tell us why you are quitting Studyflash?')
		return
	}
	const entryId = uuidv4()
	const supabaseEdgeFunctionUrl = import.meta.env.VITE_SUPABASE_EDGE_FUNCTIONS_BASE_URL
	const emailResponse = await supabase.functions.invoke('delete-account', {
		body: {
			url: `${supabaseEdgeFunctionUrl}/reactivate-account?id=${entryId}`,
			userEmail: userStore.user.email,
			entryId: entryId,
			reason: reason.value
		},
	})
	if (emailResponse.error) {
		toast(t('message.errorCodes.settings.accountDeleteFailed'))
		return
	}
	closeSettings()
	userStore.signOut(router)
}
function handleCancelDeleting() {
	showDeleteAccountPopup.value = false
}
const avatarUrl = computed(() => {
	if (userStore.user.avatar_url) {
		return userStore.user.avatar_url;
	}
	return null;
})

const handleSelectPicture = () => {
	document.getElementById('profile-photo-input').click()
}
const handlePhotoSelected = async (e) => {
	const newAvatar = e.target.files[0]
	const response = await userStore.setNewAvatar(newAvatar, userStore.getCurrentUserId(), userStore.user.auth_id)
	if (response.error) {
		console.error(response.error)
		toast.error(t('message.errorCodes.settings.updateProfilePhotoFailed'))
		const errorMessage = `Updating profile photo failed ${response.error.message}`
		captureException(new Error(errorMessage), {
			extra: {
				originalError: response.error
			}
		})
	}
}

const handleSubmitNewName = async () => {
	if (!currentName.value) return
	const response = await UserService.updateDisplayName(currentName.value, userStore.getCurrentUserId())
	if (response.error) {
		console.error(response.error)
		toast.error(t('message.errorCodes.settings.updateDisplayNameFailed'))
		const errorMessage = `Updating display name failed ${response.error.message}`
		captureException(new Error(errorMessage), {
			extra: {
				originalError: response.error
			}
		})
		return
	}

	editName.value = false
}
//TODO: extract all tabs into their own component
</script>

<template>
	<GenericPopup :showPopup="showDeleteAccountPopup" :submitAction="handleDeleteAccount"
		:cancelAction="handleCancelDeleting" :submitText="$t('message.settings.delete')"
		:cancelText="$t('message.settings.discard')" :popupStyles="{ desktop: { zIndex: 9 }, mobile: { zIndex: 9 } }"
		:title="$t('message.settings.deleteAccount')" @hide-popup="handleCancelDeleting">

		<p>{{ $t('message.settings.deleteAccountConfirmation1') }}</p>
		<p>{{ $t('message.settings.deleteAccountConfirmation2') }}</p>
		<p>Please tell us why:</p>
		<textarea v-model="reason" aria-multiline="true" />
	</GenericPopup>
	<div @click="closeSettings" :class="windowSizeStore.displaySettings ? 'popup-background-open' : 'popup-background'" id="settingsBg">
		<div :class="windowSizeStore.displaySettings ? 'settings-popup-open' : 'settings-popup'" v-on:click.stop
			id="settings">
			<div class="settings-content">
				<div class="settings-header">
					<img v-if="!windowSizeStore.isDesktop" @click="closeSettings" src="../../assets/left-arrow.svg"
						style="width: 24px; height: 24px" />
					<p class="standard_text" style="margin-bottom: 0; font-size: 20px">
						{{ $t('message.settings.settings') }}
					</p>
					<p v-if="!windowSizeStore.isDesktop" @click="closeSettings" class="secondary"
						style="padding: 0; border: 0; margin: 0">
						{{ $t('message.settings.closeButton') }}
					</p>
					<button v-else class="secondary" style="margin-bottom: 0" @click="closeSettings">
						{{ $t('message.settings.closeButton') }}
					</button>
				</div>
				<div class="settings-body">
					<div class="settings-menu-horizontal">
						<ul>
							<span v-for="(page, index) in pages" :key="index">
								<li v-if="shouldShowTab(page)" @click.stop="currentPage = page"
									:class="{ active: currentPage === page }">
									{{ $t(`message.settings.${page}`) }}
								</li>
							</span>
						</ul>
					</div>
					<div class="settings-page">
						<div v-if="currentPage === 'account'">
							<h1 class="standard_text" style="">{{ $t('message.settings.personalInfo') }}</h1>
							<div class="container" style="align-items: flex-start">
								<div class="profile-picture-container">
									<img v-if="avatarUrl" :src="avatarUrl" alt="User Avatar"
										class="avatar profile-photo" style="width: 72px; height: 72px;" loading="lazy"
										referrerpolicy="no-referrer" />
									<img v-else src="../../assets/avatar.svg" alt="User Avatar"
										class="avatar profile-photo" style="width: 72px; height: 72px;"
										loading="lazy" />
									<fa-icon icon="fa-solid fa-camera" class="invisible-icon"
										@click="handleSelectPicture"></fa-icon>
									<input type="file" name="profile-photo" id="profile-photo-input"
										style="display: none;" @change="handlePhotoSelected">
								</div>
								<div style="
										display: flex;
										flex-direction: column;
										padding-left: 10px;
										align-items: left;
										justify-content: flex-start;
									">
									<div style="margin: 0">
										<div v-if="!editName">
											<p class="standard_text"
												style="font-size: 16px; margin: 0px; line-height: 1.5">
												{{ currentName }}
												<fa-icon icon="fa-solid fa-pencil" class="edit-icon"
													@click="editName = true"></fa-icon>
											</p>
										</div>
										<div v-else style="display: flex; margin-bottom: 1rem;">
											<input type="text" name="newName" id="newName" v-model="currentName">
											<button @click="handleSubmitNewName" class="delete-account"
												style="margin-left: 1rem;">Submit</button>
										</div>
										<p class="standard_text"
											style="font-size: 12px; color: #9a9ea6; margin: 0px; line-height: 1.5">
											{{ userStore.user.email }}
										</p>
									</div>
								</div>
							</div>
							<p style="font-size: 14px; margin-bottom: 8px">
								{{ $t('message.settings.language') }}:
							</p>

							<button @click="changeLocale('en')" style="margin: 0; padding: 0">
								<img src="../../assets/english.png" class="locale-icon"
									:class="$i18n.locale === 'en' ? 'active-icon' : ''" />
							</button>

							<button @click="changeLocale('de')" style="margin-left: 8px; padding: 0">
								<img src="../../assets/german.png" class="locale-icon"
									:class="$i18n.locale === 'de' ? 'active-icon' : ''" />
							</button>

							<div style="margin-top: 16px; display: flex; justify-content: flex-end; width: 100%">
								<button class="delete-account" @click="handleOpenDeleteAccountPopup">
									{{ $t('message.settings.deleteAccount') }}
								</button>
							</div>
						</div>
						<div v-if="currentPage === 'tokens'">
							<h2 class="standard_text">{{ $t('message.settings.tokensCaps') }}</h2>
							<p class="label">{{ $t('message.settings.balance') }}:</p>
							<p class="info" style="margin: 0; font-size: 17px; font-weight: 500">
								{{ tokenBalance }} {{ $t('message.settings.tokens') }}
							</p>
							<p class="legacy-tokens">
								{{ $t('message.settings.legacyTokensText') }}
							</p>
						</div>
						<SubscriptionTab v-if="currentPage == 'subscription'" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
textarea {
	width: 100%;
	padding: 0.5rem;
	border-radius: 5px;
	border: 1px solid #f1e8f4;
	font-size: 1rem;
	margin-bottom: 0.5rem;
	resize: none;
	color: #666;
	min-height: 6rem;
}

.legacy-tokens {
	color: #9a9ea6;
	font-size: 12px;
	margin: 24px 0 0 0;
}

.info {
	color: #7a219e;
}

.label {
	font-size: 16px;
	color: #4a5361;
}

.edit-icon {
	cursor: pointer;
}

.profile-picture-container {
	position: relative;
	cursor: pointer;
}

.profile-picture-container:hover .invisible-icon {
	opacity: 1;
	height: 36px;
	width: 36px;
}

.profile-picture-container:hover .profile-photo {
	filter: brightness(0.5);
}

.invisible-icon {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	opacity: 0;
	color: white;
	height: 24px;
	width: 24px;
	transition: height 0.4s ease-in-out;
}

.delete-account {
	padding: 10px 20px;
	border: 1px solid #f1e8f4;
	border-radius: 8px;
	width: max-content;
	background-color: #7a219e;
	color: white;
	font-size: 12px;
	font-weight: 600;
}

.delete-account:hover {
	opacity: 60%;
}

.secondary:hover {
	background-color: #f1e8f4;
	border-radius: var(--custom-border-radius);
}

.error {
	border: 1px solid red !important;
}

.locale-icon {
	height: 24px;
	width: 24px;
	border-radius: 50%;
}

.active-icon {
	border: 2px solid #7a219e;
}
</style>
