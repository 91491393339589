const top = 0
const parent = (i) => ((i + 1) >>> 1) - 1
const left = (i) => (i << 1) + 1
const right = (i) => (i + 1) << 1
const cardTypePriority = { learning: 0, lapsed: 1, new: 2, review: 3 }
/**
 * Comparator function for flashcards
 * @param {Object} a - First flashcard
 * @param {Object} b - Second flashcard
 * @param {string} last_studied_id - ID of the last studied flashcard
 * @returns {boolean} True if 'a' has higher priority, false otherwise
 */
const flashcardComparator = (a, b, last_studied_id) => {
	const now = new Date().getTime()
	const twoMinutesLater = now + 2 * 60 * 1000
	const aIsDueSoon = new Date(a.next_review).getTime() <= twoMinutesLater
	const bIsDueSoon = new Date(b.next_review).getTime() <= twoMinutesLater

	// Avoid showing studied card twice in a row
	if (a.id === last_studied_id) return false
	if (b.id === last_studied_id) return true

	if (aIsDueSoon !== bIsDueSoon) {
		return aIsDueSoon
	}

	if (cardTypePriority[a.type] !== cardTypePriority[b.type]) {
		return cardTypePriority[a.type] < cardTypePriority[b.type]
	}
	return new Date(a.next_review).getTime() < new Date(b.next_review).getTime()
}
/**
 * A priority queue implementation specifically designed for flashcards.
 * It uses a max heap data structure where the flashcard with the highest
 * priority (determined by the comparator function) is at the top.
 */
export default class PriorityQueue {
	constructor(comparator = flashcardComparator) {
		this._heap = []
		this._comparator = comparator
		this.last_studied_id = null
	}
	size() {
		return this._heap.length
	}
	isEmpty() {
		return this.size() == 0
	}
	peek() {
		return this._heap[top]
	}
	push(...values) {
		values.forEach((value) => {
			this._heap.push(value)
			this._siftUp()
		})
		return this.size()
	}
	pop() {
		if (this.isEmpty()) return null
		const poppedValue = this.peek()
		this.last_studied_id = poppedValue.id
		const bottom = this.size() - 1
		if (bottom > top) {
			this._swap(top, bottom)
		}
		this._heap.pop()
		this._siftDown()
		return poppedValue
	}
	replace(value) {
		const replacedValue = this.peek()
		this._heap[top] = value
		this._siftDown()
		return replacedValue
	}
	remove(id) {
		const index = this._heap.findIndex((item) => item.id === id)
		if (index === -1) {
			return null
		}
		if (index === this.size() - 1) {
			return this._heap.pop()
		}
		this._swap(index, this.size() - 1)
		const removedValue = this._heap.pop()
		this._siftDown(index)
		this._siftUp(index)
		return removedValue
	}
	_higherPriority(i, j) {
		return this._comparator(this._heap[i], this._heap[j], this.last_studied_id)
	}
	_swap(i, j) {
		;[this._heap[i], this._heap[j]] = [this._heap[j], this._heap[i]]
	}
	_siftUp() {
		let node = this.size() - 1
		while (node > top && this._higherPriority(node, parent(node))) {
			this._swap(node, parent(node))
			node = parent(node)
		}
	}
	_siftDown() {
		let node = top
		while (
			(left(node) < this.size() && this._higherPriority(left(node), node)) ||
			(right(node) < this.size() && this._higherPriority(right(node), node))
		) {
			let maxChild =
				right(node) < this.size() && this._higherPriority(right(node), left(node))
					? right(node)
					: left(node)
			this._swap(node, maxChild)
			node = maxChild
		}
	}
	printAndClearQueue() {
		const size = this.size()
		for (let i = 0; i < size; i++) {
			this.pop()
		}
	}
	toArray() {
		return this._heap.slice()
	}
}
