import { createClient } from '@supabase/supabase-js'

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY

const customFetchWithRetry = async (url, options, retries = 3, cooldown = 100) => {
  let response;
  for (let i = 0; i < retries; i++) {
    try {
      response = await fetch(url, options);

      if (response.ok) {
        return response;
      }

      if (response.status >= 400 && response.status < 500) {
        console.error(`Client error: ${response.status} ${response.statusText}`);
        return response;
      }
      if (response.status >= 500 && response.status < 600) {
        console.error(`Internal server error: ${response.status} ${response.statusText}`);
        return response;
      }

      console.error(`Attempt ${i + 1} failed: ${response.statusText}`);

    } catch (error) {
      console.error(`Attempt ${i + 1} failed:`, error.message);
      console.error(url)
      console.error(options)
    }
    if (i < retries - 1) {
      await new Promise(resolve => setTimeout(resolve, cooldown));
    }
  }
  console.error(`Failed to fetch: ${url}`);
  return response
};

export function removeRealtimeSubscription(subscriptionObject) {
  supabase.removeChannel(subscriptionObject)
}


export const supabase = createClient(supabaseUrl, supabaseAnonKey,
  {
    global: {
      fetch: (url, options) => customFetchWithRetry(url, options),
    }
  }
)

