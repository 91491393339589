<!-- eslint-disable vue/multi-word-component-names -->
<script>
import {
	useDeckStore,
	useUserStore,
	useWindowSizeStore,
	useSubscriptionStore,
} from '../store/store.js'
import StudyPageNav from '../components/study-page/StudyPageNav.vue'
import CardComponent from '../components/study-page/CardComponent.vue'
import EditCard from '../components/study-page/EditCard.vue'
import StudyingFinished from '../components/study-page/StudyingFinished.vue'
import { toast } from 'vue3-toastify'
import GenericPopup from '../components/common/GenericPopup.vue'
import UndoDeleteCard from '../components/common/UndoDeleteCard.vue'
import { useI18n } from 'vue-i18n'
import { supabase } from '../common/database.js'
import { calculateRemainingTime } from '../common/spaced_repetition/card_handle_helper.ts'
import { SessionService } from '../services/session.service.js'
import LearningService from '../common/spaced_repetition/learning_service.ts'
import { h } from 'vue'
import LoadingComponent from '../components/deck-view/LoadingComponent.vue'
import { captureException } from "@sentry/vue";
import { UserService } from '../services/user.service.js'

export default {
	setup() {
		const deckStore = useDeckStore()
		const userStore = useUserStore()
		const windowSizeStore = useWindowSizeStore()
		const { locale } = useI18n({ useScope: 'global' })
		const subscriptionStore = useSubscriptionStore()

		return {
			deckStore,
			userStore,
			windowSizeStore,
			locale,
			subscriptionStore
		}
	},
	data() {
		return {
			showAnswer: false,
			editMode: false,
			finished: false,
			currentSession: null,
			showAreYouSurePopup: false,
			popupStyles: {
				desktop: {},
				mobile: {},
			},
			currentCard: null,
			loading: true,
			deckId: null,
			learningService: new LearningService(),
			shouldSetGeneralFeedbackFlag: false,
			cardsLearned: 0
		}
	},
	async mounted() {
		this.deckId = this.$route.params.id
		this.$posthog.capture('study_flow:start_session', { id: this.deckId ?? 'All decks', isCustomSession: false })
		const deckIdArray = this.deckStore.newDeckTree.getAllChildrenIds(this.deckId)
		await this.learningService.init(this.deckId, deckIdArray, this.subscriptionStore.getSubscriptionStatus() === 0)
		if (this.userStore.user && this.userStore.user.show_nps_popup) this.shouldSetGeneralFeedbackFlag = true
		this.currentCard = this.learningService.current
		if (!this.currentCard) {
			this.finished = true
			this.$posthog.capture('study_flow:complete_session', { customSession: false, cardsLearned: this.cardsLearned })
			return
		}
		window.addEventListener('keyup', this.handleKeyUp)
		this.startSession()
		this.loading = false
	},
	beforeUnmount() {
		if (this.subscriptionStore.getSubscriptionStatus() === 0) {
			const currentDeck = this.deckStore.decks.find(deck => deck.id === this.deckId)
			if (currentDeck && currentDeck.deck_rating === null) {
				this.deckStore.setFeedbackDeckId(this.deckId)
			}
		}
		if (this.shouldSetGeneralFeedbackFlag) {
			this.userStore.toggleAskForPromoterScoreRating()
		}
		window.removeEventListener('keyup', this.handleKeyUp)
	},
	methods: {
		async closePopup() {
			const response = await UserService.setSeenSpacedRepetitionPopup(this.userStore.getCurrentUserId())
			if (response.error) {
				console.error(response.error)
				const errorMessage = `setting seen_spaced_repetition_popup failed ${response.error.message}`
				captureException(new Error(errorMessage), {
					extra: {
						error: response.error,
					}
				})
			}
		},
		//TODO: refactor this function
		handleKeyUp(event) {
			if (this.editMode) return

			const KEY_CODES = {
				SPACEBAR: 32,
				ONE: 49,
				TWO: 50,
				THREE: 51,
				FOUR: 52,
				ONE_NUMPAD: 97,
				TWO_NUMPAD: 98,
				THREE_NUMPAD: 99,
				FOUR_NUMPAD: 100,
			}

			if (event.keyCode === KEY_CODES.SPACEBAR) {
				this.toggleShowAnswer()
			} else if (event.keyCode === KEY_CODES.ONE || event.keyCode === KEY_CODES.ONE_NUMPAD) {
				if (!this.showAnswer) return
				this.nextCard('easy')
			} else if (event.keyCode === KEY_CODES.TWO || event.keyCode === KEY_CODES.TWO_NUMPAD) {
				if (!this.showAnswer) return
				this.nextCard('good')
			} else if (event.keyCode === KEY_CODES.THREE || event.keyCode === KEY_CODES.THREE_NUMPAD) {
				if (!this.showAnswer) return
				this.nextCard('hard')
			} else if (event.keyCode === KEY_CODES.FOUR || event.keyCode === KEY_CODES.FOUR_NUMPAD) {
				if (!this.showAnswer) return
				this.nextCard('again')
			}
		},
		toggleShowAnswer() {
			this.showAnswer = !this.showAnswer
		},
		getDeckHierarchy() {
			const res = this.deckStore.getAncestors(this.$route.params.id).reverse()
			return res
		},
		toggleEdit() {
			this.editMode = !this.editMode
		},
		async startSession() {
			let sessionCreationResponse = await SessionService.startSession(this.userStore.getCurrentUserId())
			if (sessionCreationResponse.error) {
				console.error(sessionCreationResponse.error)
				const errorMessage = `session creation failed ${sessionCreationResponse.error.message}`
				captureException(new Error(errorMessage), {
					extra: {
						error: sessionCreationResponse.error,
					}
				})
				toast.error(this.$t('message.errorCodes.study.progressSessionCreationFailed'))
				return
			}
			this.currentSession = sessionCreationResponse.data
		},
		async handleUpdateSession() {
			if (!this.currentSession) return
			const sessionUpdateResponse = await supabase.rpc('update_session', { sessionid: this.currentSession.id })
			if (sessionUpdateResponse.error) {
				console.error(sessionUpdateResponse.error)
				const errorMessage = `updating session failed ${sessionUpdateResponse.error.message}`
				captureException(new Error(errorMessage), {
					extra: {
						error: sessionUpdateResponse.error,
						sessionId: this.currentSession.id
					}
				})
				toast.error(this.$t('message.errorCodes.study.progressSessionUpdateFailed'))
				return
			}
		},
		handleHideDeletePopup() {
			this.showAreYouSurePopup = false
		},
		handleShowDeletePopup() {
			this.showAreYouSurePopup = true
		},
		getTimes() {
			if (!this.currentCard) return ['1min', '10min', '1day', '4day']
			const res = calculateRemainingTime(this.currentCard)
			if (res.length != 4 || !res[0] || !res[1] || !res[2] || !res[3]) {
				console.error(this.currentCard)
				console.error(res)
				captureException(`time calculation error, current card ${this.currentCard}, res ${this.res}`)
				return ['1min', '10min', '1day', '4day']
			}
			return res
		},
		async nextCard(option) {
			this.cardsLearned++
			this.$posthog.capture('study_flow:rate_flashcard', { deckId: this.deckId, cardId: this.currentCard.id, rating: option })
			await this.learningService.rateCard(option)
			this.currentCard = this.learningService.current
			this.handleUpdateSession()
			if (!this.currentCard) {
				this.finished = true
				this.$posthog.capture('study_flow:complete_session', { customSession: false, cardsLearned: this.cardsLearned })
			}
			this.showAnswer = false
		},
		async showPreviousCard() {
			await this.learningService.revertLastRating()
			this.showAnswer = false
			this.currentCard = this.learningService.current
		},
		async saveChanges(data) {
			await this.learningService.updateQuestionAndAnswer(data.question, data.answer)
			this.$posthog.capture('general:edit_flashcard', { cardId: this.currentCard.id, deckId: this.deckId })
			this.currentCard = this.learningService.current
			this.editMode = false
		},
		async handleReinsert() {
			await this.learningService.restoreCard()
			this.showAnswer = false
			this.currentCard = this.learningService.current
			if (this.finished) this.finished = false
		},
		async handleDelete() {
			await this.learningService.deleteCard()
			this.currentCard = this.learningService.current
			this.editMode = false
			this.showAreYouSurePopup = false
			this.showAnswer = false

			toast(h(UndoDeleteCard, { card: this.learningService.deleted, language: this.locale, handleReinsert: (data) => this.handleReinsert(data) }), {
				closeOnClick: true,
				autoClose: 8000,
				position: this.windowSizeStore.isDesktop
					? toast.POSITION.BOTTOM_RIGHT
					: toast.POSITION.TOP_CENTER,
			})
			if (!this.currentCard) {
				this.finished = true
				this.$posthog.capture('study_flow:complete_session', { customSession: false, cardsLearned: this.cardsLearned })
			}
		},
	},
	components: {
		StudyPageNav,
		CardComponent,
		EditCard,
		StudyingFinished,
		GenericPopup,
		LoadingComponent,
	},
}
</script>

<template>
	<GenericPopup :showPopup="showAreYouSurePopup" :cancelAction="handleHideDeletePopup"
		:cancelText="$t('message.deckPage.cancel')" :submitText="$t('message.deckPage.confirm')"
		:isInformational="false" @hide-popup="handleHideDeletePopup" :popupStyles="popupStyles"
		:submitAction="handleDelete" :title="$t('message.deckPage.confirmDeletion')" :isDeleteConfirm="true">
		<p style="font-size: 14px">{{ $t('message.deckPage.areYouSure') }}</p>
	</GenericPopup>
	<GenericPopup :showPopup="userStore.user ? !userStore.user.seen_spaced_repetition_popup : false"
		:cancelAction="closePopup" :cancelText="$t('message.explanationPopup.gotIt')" :isInformational="true"
		@hide-popup="closePopup" :popupStyles="popupStyles" :title="$t('message.explanationPopup.spacedRepetition')"
		:isDeleteConfirm="false" :isLarge="true">
		<p class="popup-text">
			{{ $t('message.explanationPopup.text1') }}
		</p>
		<p class="popup-text">
			{{ $t('message.explanationPopup.text2') }}
		</p>
		<p class="popup-text">
			{{ $t('message.explanationPopup.text3') }}
		</p>
		<ul class="popup-list">
			<li class="popup-text">
				{{ $t('message.explanationPopup.li11') }}<b>{{ $t('message.explanationPopup.li12') }}</b>{{
					$t('message.explanationPopup.li13') }}
			</li>
			<li class="popup-text">
				{{ $t('message.explanationPopup.li21') }}<b>{{ $t('message.explanationPopup.li22') }}</b>{{
					$t('message.explanationPopup.li23') }}
			</li>
		</ul>
	</GenericPopup>
	<StudyPageNav :deckInfo="getDeckHierarchy()" @toggle-edit="toggleEdit" :editMode="editMode"
		@delete-card="handleShowDeletePopup" :newCount="learningService.queueCounter.new || 0"
		:learnCount="learningService.queueCounter.learning || 0" :reviewCount="learningService.queueCounter.review || 0"
		:currentCard="currentCard" />
	<div class="temp-container" id="study">
		<StudyingFinished v-if="finished" />
		<LoadingComponent v-else-if="!finished && loading" />
		<div class="content-container" v-else>
			<div class="view" v-if="!editMode">
				<div class="command-buttons">
					<button class="previous-button" v-if="learningService.prev" @click="showPreviousCard">
						<img src="../assets/StudyPage/previous.svg" alt="Previous icon" />
						&nbsp;&nbsp;{{ $t('message.studyPage.buttonPrevious') }}
					</button>
					<div v-else></div>
					<button class="delete-button" @click="handleShowDeletePopup" v-if="windowSizeStore.isDesktop">
						{{ $t('message.studyPage.deleteButton') }}
					</button>
				</div>
				<div v-if="currentCard != null">
					<CardComponent :cardInfo="currentCard" :showAnswer="showAnswer" @show-answer="toggleShowAnswer"
						:isDesktop="windowSizeStore.isDesktop" />
				</div>
				<button v-if="!showAnswer && windowSizeStore.isDesktop" @click="toggleShowAnswer"
					class="show-answer-button">
					<img src="../assets/StudyPage/sparcles.svg" alt="Sparcles icon" />
					&nbsp;&nbsp;{{ $t('message.studyPage.showAnswer') }}
				</button>
				<div v-else-if="showAnswer" class="difficulty-buttons">
					<button @click="nextCard('again')" class="difficulty-button again">
						<img src="../assets/StudyPage/again.svg" alt="Repeat icon" />
						<p>
							{{ $t('message.studyPage.buttonAgain') }} <span class="avoid-wrap">(&lt;1m)</span>
						</p>
					</button>
					<button @click="nextCard('hard')" class="difficulty-button hard">
						<img src="../assets/StudyPage/hard.svg" alt="Hard icon" />
						<p>
							{{ $t('message.studyPage.buttonHard') }}
							<span class="avoid-wrap">({{ getTimes()[1] }}) </span>
						</p>
					</button>
					<button @click="nextCard('good')" class="difficulty-button good">
						<img src="../assets/StudyPage/good.svg" alt="Good icon" />
						<p>
							{{ $t('message.studyPage.buttonGood') }}
							<span class="avoid-wrap">({{ getTimes()[2] }})</span>
						</p>
					</button>
					<button @click="nextCard('easy')" class="difficulty-button easy">
						<img src="../assets/StudyPage/easy.svg" alt="Easy icon" />
						<p>
							{{ $t('message.studyPage.buttonEasy') }}
							<span class="avoid-wrap">({{ getTimes()[3] }})</span>
						</p>
					</button>
				</div>
			</div>


			<div class="edit" v-else>
				<button class="delete-button edit-delete-button" @click="handleShowDeletePopup"
					v-if="windowSizeStore.isDesktop">
					{{ $t('message.studyPage.deleteButton') }}
				</button>
				<EditCard @save-changes="saveChanges($event)" :question="currentCard.question"
					:answer="currentCard.answer" />
			</div>
		</div>
	</div>
</template>

<style scoped>
.popup-list {
	gap: 16px;
}

.popup-wrapper {
	position: fixed;
	top: 2%;
	left: 50%;
	width: 488px;
	min-height: 300px;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1;
	transform: translateX(-50%);
}

@media (max-width: 750px) {
	.popup-wrapper {
		width: 90%;
		top: 1.5%;
	}
}

.popup-content {
	background-color: #fff;
	border-radius: 8px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
	z-index: 2;
	padding: 24px 32px 32px 32px;
	max-height: 95vh;
	overflow-y: auto;
}

.popup-text {
	font-size: 16px;
	color: #4a5361;
	margin-bottom: 24px;
}

.view {
	height: 100%;
}

.edit-delete-button {
	align-self: flex-end;
}

.delete-button {
	background-color: #7a219e;
	color: white;
	padding: 0.5rem 1rem;
	border-radius: 8px;
	font-size: 14px;
}

.command-buttons {
	min-height: 24px;
	display: flex;
	justify-content: space-between;
	padding-bottom: 1rem;
}

.temp-container {
	width: 100%;
	flex: 1;
	background-color: #fbf8fc;
	height: calc(100% - 110px);
	padding: 24px;
	padding-top: 0;
}

.content-container {
	width: 50%;
	margin: 1rem auto;
	height: 100%;
}

@media (max-width: 1200px) {
	.content-container {
		width: 75%;
	}
}

@media (max-width: 900px) {
	.content-container {
		width: 100%;
		margin: 1rem auto;
	}

	.temp-container {
		padding: 16px;
	}
}

.edit {
	width: 100%;
	margin: 1rem auto;
	display: flex;
	flex-direction: column;
	row-gap: 1rem;
}

.previous-button {
	display: flex;
	align-items: center;
	background-color: rgba(0, 0, 0, 0);
	color: #a264bb;
	font-size: 16px;
}

.previous-button:hover {
	font-weight: 500;
	color: #a264bb;
}

.show-answer-button {
	background-color: #7a219e;
	color: white;
	margin-top: 1rem;
	width: 100%;
	min-height: 80px;
	padding: 10px 24px;
	border-radius: 8px;
	font-size: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 700;
}

.show-answer-button:hover {
	background-color: #944cb0;
}

.difficulty-buttons {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
	justify-content: space-between;
	padding: 1rem 0;
}

.difficulty-button {
	flex-basis: calc(50% - 1rem);
	min-width: 0;
	min-height: 80px;
	padding: 1rem;
	box-sizing: border-box;
	border-radius: 8px;
}

.difficulty-button>p {
	font-weight: 700;
}

@media (min-width: 600px) {
	.difficulty-button {
		flex-basis: calc(25% - 1rem);
	}
}

.again {
	background-color: #f8f4ea;
	color: #ffc844;
	border: 1px solid #ffc844;
}

.again:hover {
	background-color: #f9f5f2;
}

.hard {
	background-color: #fff2f1;
	color: #ef585d;
	border: 1px solid #ef585d;
}

.hard:hover {
	background-color: #fdf4f6;
}

.good {
	background-color: #ccebfe;
	color: #259aec;
	border: 1px solid #259aec;
}

.good:hover {
	background-color: #e3f2fc;
}

.easy {
	background-color: #e5faec;
	color: #44ca2e;
	border: 1px solid #44ca2e;
}

.easy:hover {
	background-color: #f0f8f3;
}

.avoid-wrap {
	display: inline-block;
}
</style>