<script>
import { toast } from 'vue3-toastify';
import { submitPromoterScore } from '../../services/promoter.score.service.js';
import { useUserStore } from '../../store/store.js'
import RatingScale from './RatingScale.vue';
import { captureException } from "@sentry/vue";

export default {
    name: 'GenericPopup',
    setup() {
        const userStore = useUserStore()

        return {
            userStore
        }
    },
    data() {
        return {
            selectedOption: 10,
            currentStep: 0,
            feedback: '',
            showPrompt: false
        }
    },
    methods: {
        async closePopup() {
            const response = await this.userStore.setNpsFlagFalse()
            if (!response) {
                console.error('No user logged in!')
                return
            }
            if (response.error) {
                console.error(response.error)
                const errorMessage = `setNpsFlagFalse failed, ${response.error?.message}`
                captureException(new Error(errorMessage),
                    {
                        extra: {
                            error: response.error,
                        }
                    }
                )

            }
        },
        updateSelectedOption(newOption) {
            this.selectedOption = newOption
        },
        async nextStepOrSubmit() {
            if (this.currentStep === 0) {
                this.currentStep = 1
                return
            }
            if (this.selectedOption < 7 && !this.showPrompt) {
                this.showPrompt = true
                return
            }
            const response = await submitPromoterScore({
                rating: this.selectedOption,
                feedback: this.feedback,
                created_by: this.userStore.getCurrentUserId()
            })
            if (response.error) {
                console.error(response.error)
                toast.error(this.$t('message.errorCodes.general.npsRatingFailed'))
                const errorMessage = `Failed to submit promoter score: ${response.error.message || 'Unknown error occurred'}`
                captureException(new Error(errorMessage), {
                    extra: {
                        "originalError": JSON.stringify(response.error),
                        "rating": this.selectedOption,
                        "feedback": this.feedback
                    }
                })
                return
            }
            this.closePopup()
        }
    },
    components: { RatingScale }
};
</script>

<template>
    <div class="popup-background-open" @click="closePopup"></div>
    <div class="popup">
        <div class="popup-header-no-title">
            <fa-icon icon="fa-sold fa-x" class="icon color-icon" @click="closePopup"></fa-icon>
        </div>
        <div class="popup-content">
            <p class="general-feedback-title" v-if="currentStep === 0">{{ $t('message.generalFeedback.question') }}</p>
            <RatingScale :selectedOption="selectedOption" :updateSelectedOption="updateSelectedOption"
                v-if="currentStep === 0" :tooltipGood="$t('message.generalFeedback.ratingGood')"
                :tooltipBad="$t('message.generalFeedback.ratingBad')" />
            <div v-else>
                <p class="popup-text">{{ $t('message.generalFeedback.why') }}</p>
                <p class="popup-text">{{ $t('message.generalFeedback.tellUsMore') }}</p>
                <textarea class="text-area" :placeholder="$t('message.generalFeedback.yourAnswerHere')"
                    v-model="feedback" />
                <p class="popup-text" v-if="showPrompt">{{ $t('message.generalFeedback.detractorText') }}</p>
            </div>
        </div>
        <div class="popup-actions">
            <button class="submit-button action-button" @click="nextStepOrSubmit">
                <fa-icon v-if="currentStep === 0" icon="fa-solid fa-angle-right" class="icon"></fa-icon>
                <fa-icon v-else icon="fa-solid fa-paper-plane" class="icon"></fa-icon>
            </button>
        </div>
    </div>
</template>

<style scoped>
.popup-text {
    font-size: 1rem;
}

.general-feedback-title {
    font-size: 1.4rem;
    line-height: 1.4;
    margin-top: 0;
}

::placeholder {
    color: white;
    font-size: 1rem;
}

.text-area {
    width: 100%;
    resize: none;
    height: 7rem;
    background-color: #7A219E;
    color: white;
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.7);
    font-size: 1rem;
}

.icon {
    color: #ffffff;
    width: 20px;
    height: 16px;
    cursor: pointer;
}

.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    gap: 24px;
    background-color: #7a219e;
    padding: 24px 32px 32px;
    z-index: 8;
    min-width: 50%;
    max-width: max-content;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    max-height: 100dvh;
    overflow-y: auto;
}


.popup-header-no-title {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 16px;
}

.popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.submit-button {
    background-color: #7A219E;
    color: white;
}


.submit-button:hover {
    background-color: #944CB0;
}

.action-button {
    padding: 11px 24px;
    border-radius: 8px;
    border: 1px solid #F1E8F4;
    font-weight: 700;
}

.popup-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    text-align: right;
    gap: 1rem;
}

.popup-content {
    text-align: left;
    font-size: 14px;
    line-height: 16px;
    color: #fefefe;
}

@media (max-width: 600px) {
    .popup {
        width: 90%;
    }
}

@media (max-width: 1200px) {
    .popup {
        min-width: 70%;
    }
}
</style>