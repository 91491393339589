export const mapUserToUserIdentificationDTO = (user, subscriptionStatus) => {
    const userCopy = { ...user }
    delete userCopy._deleted
    delete userCopy._modified
    delete userCopy.agreed_on_terms_of_service
    delete userCopy.show_token_popup
    delete userCopy.seen_welcome_message
    delete userCopy.seen_spaced_repetition_popup
    delete userCopy.avatar_url
    delete userCopy.first_feedback_shown
    delete userCopy.ready_to_display_beta_feedback
    userCopy.oldUser = !userCopy.subscription_user
    delete userCopy.subscription_user
    delete userCopy.show_nps_popup
    delete userCopy.show_onboarding_tour
    userCopy.subscription = subscriptionStatus


    return userCopy
}
