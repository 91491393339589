<script>
import { toast } from 'vue3-toastify'
import { supabase } from '../../common/database.js'
import {captureException} from "@sentry/vue";

export default {
    name: "UndoDeleteCard",
    props: {
        card: {
            type: Object,
            required: true
        },
        language: {
            type: String
        },
        handleReinsert: {
            type: Function
        }
    },
    methods: {
        async restore() {
            try {
                const restoreResponse = await supabase.from('flashcards').update({ _deleted: false }).eq("id", this.card.id) 
                if(this.handleReinsert != null) this.handleReinsert(this.card)
            } catch (error) {
                toast.error(this.$t('message.errorCodes.general.undoCardDelete'))
                console.error(error)
                captureException(error);
            }
        }
    }
}
</script>

<template>
    <div id="undoDeleteCard">
        {{ language === 'en' ? "Card successfully deleted!" : "Karte erfolgreich gelöscht!" }}
        <button class="undo-button" @click="restore">{{ language === 'en' ? "Undo" : "Widerrufen" }}</button>
    </div>
</template>

<style scoped>
.undo-button {
    margin-left: 5px;
    border: 1px solid #f1e8f4;
    border-radius: 8px;
    background-color: white;
    color: #7a219e;
    font-size: 14px;
    font-weight: 700;
    padding: 6px 12px;
}
#undoDeleteCard {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>