import posthog from 'posthog-js'

export default {
	install(app) {
		/**
		 * @type {import('posthog-js').PostHog}
		 */
		const instance = app.config.globalProperties.$posthog = posthog.init(import.meta.env.VITE_POSTHOGKEY, {
			api_host: 'https://studyflash-avffcrddhheqgmd0.z01.azurefd.net',
			ui_host: 'https://eu.posthog.com',
			capture_pageview: false,
			person_profiles: 'identified_only', //TODO: check always or for identified users only
			// featureFlags: {
			// 	// input the flag values here from 'posthog.getAllFlags(distinct_id)' which you can find in the server-side libraries.
			// 	'test-flag': false
			// },
		})
		app.provide('posthog', instance)
		app.config.globalProperties.$posthog = instance;
		window.posthog = instance
	},
}
