import { supabase } from '../common/database'
import { v4 as uuidv4 } from 'uuid'
import { captureException } from '@sentry/vue'

export const DeckService = {
	async create(deck) {
		if (!deck.name) {
			throw new Error('Deck name is required')
		}
		if (!deck.id) {
			deck.id = uuidv4()
		}
		if (!deck.created_at) {
			deck.created_at = new Date().toISOString()
		}
		if (!deck.number_of_chunks) {
			deck.number_of_chunks = 0
		}
		if (!deck.chunks_completed) {
			deck.chunks_completed = 0
		}
		if (!deck.chunks_failed) {
			deck.chunks_failed = 0
		}
		const response = await supabase.from('decks').insert(deck).select()
		response.deckId = deck.id
		return response
	},
	async getDeckSpecificCardCount(deckId) {
		if (!deckId) return 0 //Should absolutely never happen, there is a null safety check on invocation, but if called in another file
		const response = await supabase.from('flashcards').select('*').eq('deck_id', deckId)
		if (response.error) {
			console.error(response.error)
			const errorMessage = `Getting card count failed ${response.error.message}`
			captureException(new Error(errorMessage), {
				extra: {
					originalError: response.error,
					deckId: deckId,
				},
			})
			return 0
		}
		return response.data.length
	},
	async bulkDeleteDecks(deckIdsArray) {
		return await supabase.from('decks').update({ _deleted: true }).in('id', deckIdsArray)
	},
	async rateDeck(rating, description, deckId) {
		return await supabase
			.from('decks')
			.update({ deck_rating: rating, deck_rating_feedback: description })
			.eq('id', deckId)
	},
	async initialFetch() {
		return await supabase.from('decks').select('*').eq('_deleted', false)
	},
	subscribeToUpdate(callback) {
		return supabase
			.channel('decks_updates')
			.on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'decks' }, (payload) =>
				callback(payload)
			)
			.subscribe()
	},
	subscribeToInsert(callback) {
		return supabase
			.channel('decks_inserts')
			.on('postgres_changes', { event: 'INSERT', schema: 'public', table: 'decks' }, (payload) =>
				callback(payload)
			)
			.subscribe()
	},
	async updateParentDeckId(deckId, newParentDeckId) {
		return await supabase.from('decks').update({ parent_deck_id: newParentDeckId }).eq('id', deckId)
	},
	async updateDeck(updateDto) {
		if (!updateDto.id) return null
		return await supabase.from('decks').update(updateDto).eq('id', updateDto.id)
	},
	parseAndSortRanges(ranges) {
		const validRanges = ranges.filter(
			(range) =>
				Array.isArray(range) &&
				range.length === 2 &&
				Number.isInteger(range[0]) &&
				Number.isInteger(range[1])
		)

		validRanges.sort((a, b) => a[0] - b[0])

		const result = []
		let currentRange = null

		for (const range of validRanges) {
			if (!currentRange) {
				currentRange = range
			} else if (range[0] <= currentRange[1] + 1) {
				currentRange[1] = Math.max(currentRange[1], range[1])
			} else {
				result.push(this.formatRange(currentRange))
				currentRange = range
			}
		}

		if (currentRange) {
			result.push(this.formatRange(currentRange))
		}

		const finalResult = result.join(', ')
		return finalResult
	},
	formatRange(range) {
		if (range[0] === range[1]) {
			return `${range[0]}`
		} else {
			return `${range[0]}-${range[1]}`
		}
	},
	subscribeToSingleDeck(deckId, callbackFunction) {
		return supabase.channel('decks').on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'decks', filter: `id=eq.${deckId}` }, (payload) => callbackFunction(payload)).subscribe()
	}
}
