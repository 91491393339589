<script>
import { Editor, EditorContent } from '@tiptap/vue-3'
import TextStyle from '@tiptap/extension-text-style'
import { Color } from '@tiptap/extension-color'
import Dropcursor from '@tiptap/extension-dropcursor'
import Highlight from '@tiptap/extension-highlight'
import Superscript from '@tiptap/extension-superscript'
import Subscript from '@tiptap/extension-subscript'
import FontFamily from '@tiptap/extension-font-family'
import Code from '@tiptap/extension-code'
import { useWindowSizeStore, useUserStore } from '../../store/store.js'
import { cleanUpFileName, uploadPublicFileAndGetItsLink } from '../../common/util.js'
import ImageResize from 'tiptap-extension-resize-image'
import BlockQuote from '@tiptap/extension-blockquote'
import BulletList from '@tiptap/extension-bullet-list'
import CodeBlock from '@tiptap/extension-code-block'
import Document from '@tiptap/extension-document'
import HardBreak from '@tiptap/extension-hard-break'
import Heading from '@tiptap/extension-heading'
import HorizontalRule from '@tiptap/extension-horizontal-rule'
import ListItem from '@tiptap/extension-list-item'
import OrderedList from '@tiptap/extension-ordered-list'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import Strike from '@tiptap/extension-strike'
import History from '@tiptap/extension-history'

/**
 * Custom richtext editor
 * 
 * props:
 * modelValue: the initaial value of the content
 * if you need more props -> Contact Nikola Grbovic
 * 
 * actions:
 * On content change: emits event called content-changed where 
 * $event.target.value is the html representation os styled content
 * 
 */


export default {
    name: 'RichTextEditor',
    setup() {
        const windowSizeStore = useWindowSizeStore()
        const userStore = useUserStore()

        return {
            windowSizeStore,
            userStore
        };
    },
    data() {
        return {
            editor: null,
            selectedFont: 'Manrope',
            highlighColor: '#ffffff',
            fontFamilies: [
                { name: 'Serif', displayName: 'Serif' },
                { name: 'Monospace', displayName: 'Monospace' },
                { name: 'Mono', displayName: 'Mono' },
                { name: 'Cursive', displayName: 'Cursive' },
                { name: 'Manrope', displayName: 'Manrope' },
                { name: 'Sans-serif', displayName: 'Sans-serif' },
                { name: 'Comic Sans MS, Comic Sans', displayName: 'Comic Sans' },
            ]
        }
    },
    props: {
        modelValue: {
            type: String,
            default: '',
        },
        usage: {
            type: String,
            required: true
        },
        isPopup: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        const extensions = [
            Document,
            Paragraph,
            Text,
            BlockQuote,
            BulletList,
            CodeBlock,
            HardBreak,
            Heading,
            HorizontalRule,
            ListItem,
            OrderedList,
            Bold,
            Italic,
            Strike,
            TextStyle,
            History,
            Code.configure({
                HTMLAttributes: {
                    class: 'code-block',
                },
            }),
            Color.configure({
                types: ['textStyle'],
            }),
            Dropcursor.configure({
                width: 2,
                color: '#7A219E',
                backgroundColor: '#7A219E',
            }),
            Superscript.configure({
                HTMLAttributes: {
                    class: 'super-script',
                },
            }),
            Subscript.configure({
                HTMLAttributes: {
                    class: 'sub-script',
                },
            }),
            FontFamily.configure({
                types: ['textStyle'],
            }),
            Highlight.configure({
                HTMLAttributes: {
                    class: 'highlighted-text',
                },
                multicolor: true,
                types: ['highlight'],
            }),
            ImageResize.configure({
                inline: true,
                HTMLAttributes: {
                    class: 'card-image',
                },
                allowBase64: true,
            }),
        ];

        this.editor = new Editor({
            content: this.modelValue,
            onUpdate: ({ editor }) => {
                this.$emit('content-changed', editor.getHTML())
            },
            extensions: extensions
        })
    },
    beforeUnmount() {
        this.editor.destroy()
    },
    methods: {
        checkFont() {
            for (const font of this.fontFamilies) {
                if (this.editor.isActive('textStyle', { fontFamily: font.name })) {
                    this.selectedFont = font.name;
                    return;
                }
            }
            //Kinda dangerous since different devices could have different fonts so if we don't have font as option..?
            //I've done research and all the base fonts for each browser/OS combo should be covered
            const body = document.body;
            this.selectedFont = window.getComputedStyle(body).fontFamily;
        },
        async handleImageUpload($event) {
            let image = $event.target.files[0]
            let fileName = cleanUpFileName(image.name)
            let userId = this.userStore.getCurrentUserId()
            let filePath = userId + '/' + fileName

            const publicUrl = await uploadPublicFileAndGetItsLink('FlashcardImages', filePath, image)
            this.editor.chain().focus().setImage({ src: publicUrl }).run()
        },
        async handlePasteImage($event) {
            $event.stopPropagation();
            $event.preventDefault();

            let clipboardData = $event.clipboardData || window.clipboardData;

            if (clipboardData.files.length >= 1) {
                let userId = this.userStore.getCurrentUserId()
                let image = clipboardData.files[0]
                let fileName = image.lastModified + image.name
                let fullPath = userId + '/' + fileName
                const publicUrl = await uploadPublicFileAndGetItsLink('FlashcardImages', fullPath, image)
                this.editor.chain().focus().setImage({ src: publicUrl }).run()
            }
        }
    },
    watch: {
        modelValue(value) {
            const isSame = this.editor.getHTML() === value
            if (isSame) { return }
            this.editor.commands.setContent(value, false)
        },
        selectedFont() {
            this.editor.chain().focus().setFontFamily(this.selectedFont).run()
        }
    },
    components: { EditorContent }
}
</script>

<template>
    <div class="sticky-container" id="richTextEditor">
        <div v-if="editor" class="menu-overlay">
            <div :class="windowSizeStore.isDesktop ? 'flex-container' : 'flex-container-mobile'">
                <select v-model="selectedFont" class="font-selection" title="Font selection">
                    <option v-for="(font, index) in fontFamilies" :key="index" :value="font.name">{{ font.displayName }}
                    </option>
                </select>
                <div class="text-decor command-container">
                    <button @click="editor.chain().focus().toggleBold().run()"
                        :disabled="!editor.can().chain().focus().toggleBold().run()"
                        :class="{ 'is-active': editor.isActive('bold') }" title="Bold">
                        <fa-icon icon="fa-solid fa-bold" class="icon"></fa-icon>
                    </button>
                    <button @click="editor.chain().focus().toggleItalic().run()"
                        :disabled="!editor.can().chain().focus().toggleItalic().run()"
                        :class="{ 'is-active': editor.isActive('italic') }" title="Italic">
                        <fa-icon icon="fa-solid fa-italic" class="icon"></fa-icon>
                    </button>
                    <button @click="editor.chain().focus().toggleStrike().run()"
                        :disabled="!editor.can().chain().focus().toggleStrike().run()"
                        :class="{ 'is-active': editor.isActive('strike') }" title="Strike-trough">
                        <fa-icon icon="fa-solid fa-strikethrough" class="icon"></fa-icon>
                    </button>

                </div>
                <div class="color command-container">
                    <div style="flex: 1; width: 30px;" title="Text color">
                        <div style="position:relative; width: 30px; height: 30px;">
                            <button class="color-picker-button">
                                <fa-icon icon="fa-solid fa-t" class="icon color-icon"></fa-icon>
                            </button>
                            <input type="color" @input="editor.chain().focus().setColor($event.target.value).run()"
                                :value="editor.getAttributes('textStyle').color || '#000000'" class="color-picker">
                        </div>
                    </div>
                    <div style="flex: 1; width: 30px;" title="Highlight color">
                        <div style="position:relative; width: 30px; height: 30px;">
                            <button class="color-picker-button">
                                <fa-icon icon="fa-solid fa-highlighter" class="icon color-icon"></fa-icon>
                            </button>
                            <input type="color"
                                @input="editor.chain().focus().setHighlight({ color: $event.target.value }).run()"
                                :value="editor.getAttributes('highlight').color || '#ffffff'" class="color-picker">
                        </div>
                    </div>
                </div>

                <div class="super-sub-script command-container">
                    <button @click="editor.chain().focus().toggleSubscript().run()" title="Subscript"
                        :class="{ 'is-active': editor.isActive('subscript') }">
                        <fa-icon icon="fa-solid fa-subscript" class="icon"></fa-icon>
                    </button>
                    <button @click="editor.chain().focus().toggleSuperscript().run()" title="Superscript"
                        :class="{ 'is-active': editor.isActive('superscript') }">
                        <fa-icon icon="fa-solid fa-superscript" class="icon"></fa-icon>
                    </button>
                </div>

                <div class="text-tags command-container">
                    <button @click="editor.chain().focus().setParagraph().run()"
                        :class="{ 'is-active': editor.isActive('paragraph') }" class="font-size-button"
                        title="Paragraph">
                        <p class="font-size-p">
                            P
                        </p>
                    </button>
                    <button @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
                        :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }" class="font-size-button"
                        title="Heading 1">
                        <p class="font-size-p">
                            H1
                        </p>
                    </button>
                    <button @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
                        :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }" class="font-size-button"
                        title="Heading 2">
                        <p class="font-size-p">
                            H2
                        </p>
                    </button>
                    <button @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
                        :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }" class="font-size-button"
                        title="Heading 3">
                        <p class="font-size-p">
                            H3
                        </p>
                    </button>
                    <button @click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
                        :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }" class="font-size-button"
                        title="Heading 4">
                        <p class="font-size-p">
                            H4
                        </p>
                    </button>
                </div>
                <div class="lists command-container">
                    <button @click="editor.chain().focus().toggleBulletList().run()"
                        :class="{ 'is-active': editor.isActive('bulletList') }" title="Bullet list">
                        <fa-icon icon="fa-solid fa-list-ul" class="icon"></fa-icon>
                    </button>
                    <button @click="editor.chain().focus().toggleOrderedList().run()"
                        :class="{ 'is-active': editor.isActive('orderedList') }" title="Ordered list">
                        <fa-icon icon="fa-solid fa-list-ol" class="icon"></fa-icon>
                    </button>
                </div>
                <div class="command-container">
                    <button @click="editor.chain().focus().toggleCodeBlock().run()"
                        :class="{ 'is-active': editor.isActive('codeBlock') }" title="Code block">
                        <fa-icon icon="fa-solid fa-code" class="icon"></fa-icon>
                    </button>
                    <button @click="editor.chain().focus().toggleBlockquote().run()"
                        :class="{ 'is-active': editor.isActive('blockquote') }" title="Block quote">
                        <fa-icon icon="fa-solid fa-quote-left" class="icon"></fa-icon>
                    </button>
                </div>
                <input type="file" accept="image/*" :id="'fileInput' + usage" @change="handleImageUpload"
                    style="display: none;">
                <label class="customFileLabel" :for="'fileInput' + usage" title="Upload image">
                    <fa-icon icon="fa-solid fa-image" class="icon color-icon"></fa-icon>
                </label>
                <div class="clear command-container">
                    <button
                        @click="editor.chain().focus().unsetAllMarks().run(); editor.chain().focus().clearNodes().run()"
                        title="Clear formatting">
                        <fa-icon icon="fa-solid fa-text-slash" class="icon"></fa-icon>
                    </button>
                </div>
                <div class="breaks command-container">
                    <button @click="editor.chain().focus().setHorizontalRule().run()" title="Horizontal ruler">
                        <fa-icon icon="fa-solid fa-minus" class="icon"></fa-icon>
                    </button>
                    <button @click="editor.chain().focus().setHardBreak().run()" title="Line break">
                        <fa-icon icon="fa-solid fa-arrow-turn-down" class="icon rotate-icon"></fa-icon>
                    </button>
                </div>
                <div class="undo command-container">
                    <button @click="editor.chain().focus().undo().run()"
                        :disabled="!editor.can().chain().focus().undo().run()" title="Undo">
                        <fa-icon icon="fa-solid fa-rotate-left" class="icon"></fa-icon>
                    </button>
                    <button @click="editor.chain().focus().redo().run()"
                        :disabled="!editor.can().chain().focus().redo().run()" title="Redo">
                        <fa-icon icon="fa-solid fa-rotate-right" class="icon"></fa-icon>
                    </button>
                </div>

            </div>
        </div>
        <div :class="isPopup ? 'wysiwyg-editor wysiwyg-popup' : 'wysiwyg-editor'">
            <editor-content :editor="editor" @mouseup="checkFont" @keyup="checkFont" @paste="handlePasteImage" />
        </div>
    </div>
</template>

<style scoped>
.wysiwyg-editor {
    overflow: hidden !important;
    height: max-content !important;
    font-size: 14px;
}

.flex-container button {
    padding: 0 0.5rem;
}

.customFileLabel {
    cursor: pointer;
    background-color: #ffffff;
    color: #fff;
    border: none;
    border-radius: 5px;
    display: inline-block;
    text-align: center;
}

.font-size-button {
    padding: 0.1rem 0.5rem;

}

.font-selection {
    border: none;
    border-radius: 8px;
    min-width: 6rem;
    padding: 0;
    height: 2rem;
}

.wysiwyg-editor {
    overflow-x: hidden;
    overflow-y: scroll;
    border: 1px solid #F1E8F4;
    border-radius: 0 0 8px 8px;
}

.button-container {
    display: flex;
    justify-content: flex-end;
}

.submit-button {
    margin-top: 1rem;
    padding: 11px 24px;
    border-radius: 8px;
    border: 1px solid #F1E8F4;
    font-weight: 700;
    background-color: #7A219E;
    color: white;
}

.submit-button:hover {
    background-color: #944CB0;
}

.color {
    display: flex;
    justify-content: center;
    align-items: center;
}

.color-icon {
    color: #808080;
}

.text-tags {
    flex-wrap: wrap;
}

.text-decor {
    flex-wrap: wrap;
}

.text-tags p {
    margin: 0;
    padding: 0;
}

.icon {
    height: 13px;
}

.icon:hover {
    color: #0066CC;
}

.font-size-p {
    font-size: 13px;
    font-weight: 600;
}

.font-size-p:hover {
    color: #0066CC;
}


.rotate-icon {
    transform: rotate(90deg);
}

.command-container {
    display: flex;
    justify-content: center;
}

.flex-container {
    display: flex;
    flex-wrap: wrap;
    padding: 1rem;
    gap: 0.2rem 0.5rem;
    border: 1px solid #f1e8f4;
}

.flex-container-mobile {
    display: flex;
    flex-wrap: wrap;
    padding: 0.5rem;
    gap: 0.3rem 1rem;
    border: 1px solid #f1e8f4;
}

.sticky-container {
    background-color: white;
    margin: 0 auto;
    position: relative;
    border-radius: 8px;
}

.menu-overlay {
    background-color: white;
    z-index: 6;
    border: 1px solid #F1E8F4;
    border-radius: 8px 8px 0 0;
}

.color-picker {
    position: absolute;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 30px;
    height: 30px;
    padding: 0;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    top: 0;
    left: 0;
    opacity: 0;
}

.color-picker-button {
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    width: 30px;
}

.color-picker::-webkit-color-swatch {
    border-radius: 8px;
    border: none;
}

.color-picker::-moz-color-swatch {
    border-radius: 8px;
    border: none;
}

.editor {
    border: 1px solid black;
}

.is-active {
    color: blue;
}
</style>