const de = {
	message: {
		common: {
			fieldRequired: '*Dieses Feld ist erforderlich',
			tempErrorMessage: 'Wir haben derzeit einige technische Störungen mit unserer Infrastruktur. Daher ist die Erstellung von Decks derzeit nicht möglich. Das Lernen von Karteikarten ist von diesem Ausfall nicht betroffen. Unser Team untersucht das Problem aktiv und arbeitet daran, es so schnell wie möglich zu beheben. Grosses Sorry für die Unannehmlichkeiten.',
			studyflashTeam: "Dein Studyflash Team."
		},
		header: {
			seeWhatsNew: 'Feedback',
			tokensLeft: ' Tokens übrig',
			premiumUser: 'Premium aktiv!',
			basicUser: 'Basisbenutzer',
		},
		signInPage: {
			heading: 'Willkommen!',
			subtitle:
				'Bereit für die Lernrevolution? Erstelle dein Konto oder melde dich an, um beim Lernen Zeit zu sparen.',
			haveAccount: 'Hast du schon einen Account?',
			continueWithGoogle: 'Weiter mit Google',
			continueWithCredentials: 'Weiter mit E-Mail',
			signIn: 'Anmelden',
			or: 'oder',
			email: 'E-Mail',
			password: 'Passwort',
			passwordLengthMessage: 'Das Passwort muss mindestens 8 Zeichen lang sein.',
			passwordConfirmation: 'Passwort bestätigen',
			tos1: 'Durch die Anmeldung stimmst du den',
			tos2: 'Nutzungsbedingungen',
			tos3: 'und den',
			tos4: 'Datenschutzrichtlinien',
			tos5: 'von Studyflash zu.',
			newToStudyflash: 'Neu bei Studyflash?',
			rememberMe: 'Angemeldet bleiben',
			forgotPassword: 'Passwort vergessen',
			checkEmail: 'Überprüfe deine E-Mails',
			checkEmailMessage1:
				'Wir haben dir eine E-Mail mit einem Link zur Aktivierung deines Kontos an',
			checkEmailMessage2: 'gesendet.',
			emailAlreadyExists:
				'Diese E-Mail wurde bereits genutzt. Melde dich an oder verwende eine andere E-Mail, um dich zu registrieren.',
			unsuccessfulLogin: 'Ungültige Anmeldeinformationen, bitte versuche es erneut.',
			passwordMismatch: 'Die Passwörter unterscheiden sich.',
			tosAgreementMissing:
				'Bitte stimme den den Nutzungsbedingungen und Datenschutzrichtlinien zu.',
			deletedEmailReused:
				'Diese E-Mail wird bereits verwendet, bitte nutze eine andere E-Mail, um dich zu registrieren.',
			newPlatformResetPasswordMessage:
				'Bitte überprüfe deine E-Mail, wir haben dir einen Link zum Zurücksetzen des Passworts geschickt, damit du dich bei der neuen Plattform anmelden kannst.',
			accPermanentlyDeleted: "Dieses Konto wurde dauerhaft gelöscht am ",
			accTemporaryDeleted1: "Dieses Konto wurde gelöscht am",
			accTemporaryDeleted2: ", wir haben dir einen Reaktivierungslink geschickt!",
		},
		studyPage: {
			buttonAgain: 'Wiederholen',
			buttonHard: 'Schwer',
			buttonGood: 'Gut',
			dontShowAgain: 'Nicht mehr wiederholen',
			buttonEasy: 'Leicht',
			buttonEdit: 'Bearbeiten',
			buttonFinish: 'Fertig',
			buttonPrevious: 'Vorherige',
			showAnswer: 'Antwort anzeigen',
			done: 'Du hast alle Karteikarten in diesem Deck gelernt!',
			sessionDone: 'Du hast diese Lernsession abgeschlossen!',
			learnOther: 'Lerne andere Karteikarten!',
			finishSession: 'Lernsession beenden',
			deleteButton: 'Löschen',
			saveButton: 'Speichern',
		},
		leftNav: {
			createNewFlashcards: 'Karteikarten erstellen',
			homeButton: 'Home',
			decksButton: 'Decks',
			tokensButton: 'Abonnements',
			feedbackButton: 'Hilfe',
			inviteFriendText1: 'Lade deine FreundInnen ein &',
			inviteFriendText2: 'erhalte einen Monat Premium!',
			inviteFriendButton: 'Weiterempfehlen',
			logout: 'Ausloggen',
			noDecksToast: 'Du hast keine Decks erstellt, versuchen zuerst, ein Deck zu erstellen!',
		},
		dashboard: {
			dashboard: 'Dashboard',
			studyDaily: 'Heute gelernte Zeit',
			studyWeekly: 'Diese Woche gelernte Zeit',
			studyTimeToday: 'Heutige Lernzeit',
			yesterday: 'Seit gestern',
			cards: 'Karten',

			// streak component
			studyingFor1: 'Du hast ',
			studyingFor2: 'am Stück gelernt',
			day: 'Tag',
			days: 'Tage',
			continueStreak: 'Lerne morgen, um deinen Streak fortzusetzen',
			study: 'Lerne',
			tomorrow: 'morgen',
			today: 'heute',
			toContinue: ', um die Serie fortzusetzen.',
			cardsStudiedToday: 'Heute gelernte Karten',

			// Flashcard table
			createNewFlashcards: 'Karteikarten erstellen',
			flashcardReview: 'Lernfortschritt',
			deckName: 'Names des decks',
			new: 'Neu',
			learn: 'Lernen',
			due: 'Fällig',
			noDecks:
				"Du hast noch keine Decks erstellt. Klicke auf 'Karteikarten erstellen', um loszulegen!",
			studyAllButton: 'Alle lernen',
			created: 'Erstellt am',
			flashcards: 'Karteikarten',
		},
		settings: {
			settings: 'Einstellungen',
			closeButton: 'Schließen',
			account: 'Konto',
			password: 'Passwort',
			subscription: 'Abonnement',
			passwordCaps: 'PASSWORT',
			tokens: 'Token',
			personalInfo: 'PERSÖNLICHE INFOS',
			language: 'Sprache',
			oldPassword: 'Altes Passwort',
			newPassword: 'Neues Passwort',
			confirmPassword: 'Bestätige dein neues Passwort',
			balance: 'Guthaben',
			tokensCaps: 'TOKEN',
			changePassword: 'Passwort ändern',
			deleteAccount: 'Account löschen',
			deleteAccountConfirmation1: 'Möchtest du dein Konto wirklich löschen?',
			deleteAccountConfirmation2:
				'Dein Konto wird für immer gelöscht (und das ist eine lange Zeit).',
			delete: 'Löschen',
			discard: 'Verwerfen',
			subscriptionTitle: 'ABONNEMENT',
			currentSubscription: 'Aktuelles Abonnement',
			renewalDate: 'Verlängerungsdatum',
			inactive: 'Kein aktives Abonnement',
			noSubFound: 'Kein Abonnement gefunden',
			monthly: 'Monatsabo',
			quarterly: 'Semesterabo',
			yearly: 'Jahresabo',
			manageSubscriptions: 'Abonnements verwalten',
			legacyTokensText:
				'Tokens stellen ein Legacy Feature dar, du kannst sie weiterhin benutzen aber keine neuen kaufen. Die Tokens sind nach dem Kauf ein Jahr lang gültig.',
		},
		feedback: {
			submitFeedback: 'Hilfe',
			title: 'Titel',
			description: 'Beschreibung',
			attachImages: 'Bilder anhängen',
			dismiss: 'Abbrechen',
			submit: 'Einreichen',
			feedbackSuccess: "Deine Feedback wurde eingereicht, wir werden uns so schnell wie möglich bei dir melden!",
		},
		onboardingPage: {
			welcome: 'WILLKOMMEN',
			happyToHaveYou: 'Wir freuen uns, dich an Bord zu haben',
			countryQuestion: 'In welchem Land studierst du?',
			choseOption: 'Eine Option auswählen...',
			languageQuestion: 'Wähle deine bevorzugte Sprache',
			universityQuestion: 'Wo studierst du?',
			majorQuestion: 'Was studierst du?',
			studyMajorOptions: {
				business: 'Wirtschaftswissenschaften',
				social_sciences: 'Sozialwissenschaften (z.B. Psychologie, Soziologie)',
				humanities: 'Geisteswissenschaften (z.B. Geschichte, Sprachen, Philosophie)',
				law: 'Jura',
				medicine: 'Medizin',
				science: 'Naturwissenschaften (z.B. Biologie, Chemie, Mathematik)',
			},
			otherField: 'Andere',
			startDate: 'Wann hast du dein Studium begonnen?',
			startDateOptions: {
				fall2018: 'Herbstsemester 2018',
				spring2019: 'Fr\u00fchlingssemester 2019',
				fall2019: 'Herbstsemester 2019',
				spring2020: 'Fr\u00fchlingssemester 2020',
				fall2020: 'Herbstsemester 2020',
				spring2021: 'Fr\u00fchlingssemester 2021',
				fall2021: 'Herbstsemester 2021',
				spring2022: 'Fr\u00fchlingssemester 2022',
				fall2022: 'Herbstsemester 2022',
				spring2023: 'Fr\u00fchlingssemester 2023',
				fall2023: 'Herbstsemester 2023',
				spring2024: 'Fr\u00fchlingssemester 2024',
				fall2024: 'Herbstsemester 2024',
			},
			heardAboutUsQuestion: 'Wie bist du zu Studyflash gekommen?',
			referralCode: 'Referral Code (optional)',
			validReferralCode: 'Der Referral Code wird angewendet.',
			invalidReferralCode: 'Ungültiger Referral Code. Bitte erneut versuchen.',
			birthDayQuestion: 'Wann hast du Geburtstag? (optional)',
			heardAboutUsOptions: {
				articles: 'Artikel (PR)',
				adds: 'Werbung (Social Media oder Google Ads)',
				events: 'Events',
				student_association: 'Fachschaft',
				tiktok_studyflash: 'TikTok (von Studyflash)',
				instagram_studyflash: 'TikTok (Influencer)',
				influencer: 'Influencer',
				flyer: 'Flyer',
				word_of_mouth: 'Freunde (Mund-zu-Mund Propaganda)',
				search_engines: 'Suchmaschinen (z.B. Google Suchergebnisse)',
				linkedin: 'Linkedin',
				other: 'Other',
			},
			toc1: 'Durch die Anmeldung stimmst du den ',
			toc2: 'Nutzungsbedingungen',
			toc3: ' und den ',
			toc4: 'Datenschutzrichtlinien',
			toc5: ' von Studyflash zu.',
			tocText1:
				'Bleib auf dem Laufenden! Tritt unserer Studenten-Community bei und erhalte die neuesten Updates, exklusive Ressourcen und Angebote. Deine Privatsphäre ist uns wichtig - wir verwenden deine Daten in Übereinstimmung mit unseren ',
			tocText2: 'Datenschutzrichtlinien',
			tocText3: '. Du kannst dich jederzeit problemlos abmelden.',
			signIn: 'Anmelden',
			agreeToTos: "Bitte stimme den Nutzungsbedingungen zu",
			allFieldsRequired: "Bitte fülle alle erforderlichen Felder aus",
			highSchool: "Gymnasium"
		},
		decksPage: {
			deckOverview: 'Deckübersicht',
			organizeText: 'Organisiere, bearbeite und lerne deine Karteikarten',
			basicButton: 'Klassisch',
			restoreButton: 'Papierkorb',
			createButton: 'Neuer',
			customStudySessionsTitle: 'Klassische Lerneinheiten',
			decksTitle: 'Decks',
			infoPopupTitle: 'Klassisch 🎓',
			infoPopupText:
				'Im klassischen Lernmodus kannst du deine Karteikarten so oft lernen, wie du möchtest. Das ist ideal, wenn du einige Tage vor der Prüfung nochmal alle Karteikarten wiederholen möchtest.',
			infoPopupLabelName: 'Name der Lernsession',
			infoPopupLabelDeck: 'Deck',
			start: 'Starten',
			cancel: 'Abbrechen',
			createNewFlashcard: 'Neue Karteikarte erstellen',
			freeDeckTip:
				'*Dies ist ein von der KI generiertes Testdeck, die Anzahl der Karten ist auf 50 begrenzt. Um alle Karten freizuschalten, besuche bitte ',
			subscriptionPage: 'unseren Shop.',
			saveButton: 'Speichern',
			preventCircularDependency: "Ein Ordner kann kein Unterordner eines Unterordners sein.",
			generationMightTakeLonger: "Hinweis: Mit der Bildfunktion kann die Erstellung etwas länger dauern"
		},
		createPopup: {
			newFolder: 'Neuer Ordner',
			folderLevel: 'Ebene des Ordners (optional)',
			placeholder: 'Betriebswirtschaftslehre',
			folderName: 'Name des Ordners',
			explanation:
				'Dadurch wird ein neuer Unterordner innerhalb des oben ausgewählten Ordners erstellt.',
			cancel: 'Abbrechen',
			create: 'Erstellen',
		},
		deckPage: {
			rateDeck: 'Deck bewerten',
			updateRating: 'Update Bewertung',
			allDecks: 'Alle Decks',
			study: 'Lernen',
			addCard: 'Karte hinzufügen',
			addNewCard: 'Karte hinzufügen',
			deckEmpty:
				'Hoppla, dieses Deck scheint leer zu sein... Versuche, neue Karten hinzuzufügen! 😄',
			openEnded: 'Offene Frage',
			editCard: 'Karteikarten bearbeiten',
			tip: 'KI ist noch nicht perfekt und kann vereinzelt Fehler machen. Wir empfehlen dir daher die generierten Karteikarten kurz zu überprüfen.',
			question: 'Frage',
			answer: 'Antwort',
			save: 'Speichern',
			search: 'Nach Stichwörtern suchen',
			areYouSure: 'Bist du sicher, dass du diese Karte löschen willst?',
			confirm: 'Bestätigen',
			cancel: 'Abbrechen',
			confirmDeletion: 'Bestätige das Löschuen der Karte',
			creatingFlashcardsPopupMessage1: 'Mach dir keine Sorgen, wir sind dran! 🥰',
			creatingFlashcardsPopupMessage2:
				'Dieser Prozess könnte einen Moment dauern ⌛, aber das dient alles dazu, dir eine reibungslose und effektive Lernerfahrung zu garantieren. Danke für deine Geduld, während wir die Daten verarbeiten und deine personalisierten Lernkarten erstellen. 📚🧠',
			creatingFlashcardsPopupTitle: 'Vorbereitung deiner Lernkarten 🔎',
			creatingFlashcardsPopupClose: 'Verstanden, danke!',
			unlockCards: 'Alle Karteikarten freischalten',
			failedGenerating1: 'Ups!🙁',
			failedGenerating2:
				'\nWir haben unser Bestes gegeben, aber einige Seiten konnten wir nicht verarbeiten. Keine Sorge, du kannst aber einen Blick auf diese Seiten werfen und die Karteikarten manuell hinzuzufügen:',
			questionAndAnswerRequired: "Bitte gib eine Frage und eine Antwort für die Karteikarte ein!",
		},
		explanationPopup: {
			spacedRepetition: 'Spaced repetition',
			text1: 'Hey,',
			text2: 'Wir möchten dir kurz erklären, wie und warum unser Lernmodus funktioniert:',
			text3:
				'Unser Tool basiert auf den gleichen Algorithmen und Prinzipien wie Anki; wenn du mit Anki vertraut bist, kannst du diese Einführung gerne überspringen.',
			li11: 'Versuche die Frage selbst zu beantworten und überprüfe deine Antwort indem du die Karteikarte umdrehst. Das ',
			li12: 'aktive Abrufen',
			li13: ' von Informationen hilft deinem Gehirn dabei, sich Dinge besser zu merken als passives lesen oder wiederholen.',
			li21: 'Nachdem du die Karteikarte umgedreht hast, bewerte, wie schwer es dir fiel die Frage zu beantworten. Anhand dieser Einstufung entscheidet unser Algorithmus dann, wann du die Karteikarte wiederholen solltest. Diese ',
			li22: 'Spaced Repetition',
			li23: ' hilft dir, dir Dinge besser einzuprägen, indem dir die Karteikarten genau dann gezeigt werden, wenn du kurz davor bist, sie zu vergessen.',
			gotIt: 'Danke, verstanden!',
		},
		createPage: {
			createNewDeck: 'Erstelle ein neues Karteikarten-Deck',
			inputSlides: 'Lade deine Vorlesungsmaterialien hoch und erlebe die Magie!',
			reminder:
				'Erinnerung: Studyflash stützt sich auf Sprachmodelle, die teilweise etwas Mühe mit der Bild- und Formelverarbeitung haben.',
			folder: 'Ordner',
			placeholder: 'Betriebswirtschaftslehre',
			deck: 'Deckname',
			outputLanguage: 'Sprache der Karteikarten',
			en: 'English',
			de: 'Deutsch',
			fr: 'Französisch (funktioniert nur bei französischen Dateien)',
			sp: 'Spanisch (funktioniert nur bei spanischen Dateien)',
			createFor: 'Erstellen für ',
			tokens: ' Token',
			createSubscription: 'Deck generieren!',
			costMultiplication1:
				'Uh-oh, dein Dokument ist ziemlich umfangreich. Wir haben die Token um das ',
			costMultiplication2: '-fache erhöht, um unsere Kosten zu decken.',
			beingProcessed:
				'Deine Unterlagen werden verarbeitet! Du wirst die Ergebnisse in einem Augenblick sehen.',
			drag: 'Drag & Drop oder ',
			choseFile: 'wähle eine Datei',
			upload: ' zum Hochladen',
			supportedTypes: 'Akzeptierte Dateitypen: PDF, DOCX, PPTX.',
			fileUploaded: 'Dein Datei wurde hochgeladen',
			cantPreview: '(Auf Mobilgeräten gibt es keine Vorschau 😔)',
			previewNotAvailable: 'Vorschau nur für PDF Dateien verfügbar',
			wrongFileType: "Dieser Dateityp wird nicht unterstützt, bitte lade eine PDF-, DOCX- oder PPTX-Datei hoch.",
			subscriptionNeeded: "Du musst ein Abonnement abschliessen, um Karteikarten zu erstellen.",
			imagesOnFlashcards: "Karteikarten mit Bildern"
		},
		referral: {
			inviteFriend: 'FreundInnen einladen',
			description:
				'Hilf mit, Studyflash bekannt zu machen Dein/e Freund/in erhält 20% Rabatt und du bekommst einen kostenlosen Monat Studyflash Premium nach dem er/sie ein Abo abgeschlossen hat.',
			copyLink: 'Link kopieren',
			linkCopied: 'Link kopiert!',
			copied: 'Kopiert!',
		},
		undoDelete: {
			card: 'Karte erfolgreich gelöscht!',
			deck: 'Deck erfolgreich gelöscht!',
			undo: 'Widerrufen',
		},
		deckBin: {
			deckBin: 'Papierkorb',
			subtitle:
				'Hier siehst du die Kürzlich gelöschten Decks und kannst diese bei Bedarf wiederherstellen',
			tip: 'Tipp: Wenn du ein Deck wiederherstellst, werden automatisch auch die untergeordneten Decks und alle darin enthaltenen Karten wiederhergestellt!',
			name: 'Name:',
			deletedAt: 'Gelöscht am:',
			allDecks: 'Alle decks',
		},
		customStudySessionItem: {
			areYouSure: 'Bist du sicher, dass du diese Lerneinheit löschen möchtest: ',
			confirm: 'Bestätigen',
			cancel: 'Abbrechen',
			confirmDeletion: 'Bestätige das Löschen der Lerneinheit',
		},
		deckItem: {
			areYouSure: 'Bist du sicher, dass du dieses Deck löschen willst: ',
			confirm: 'Bestätigen',
			cancel: 'Abbrechen',
			confirmDeletion: 'Bestätige das Löschen des Decks',
		},
		welcomeMessage: {
			title: 'Willkommen bei Studyflash! 🚀',
			thrilled: 'Wir freuen uns sehr, dass du hier bist! 🎉',
			text1: 'Zum Start bei Studyflash haben wir ein Geschenk für dich: ',
			text2: 'Die erste Erstellung ist kostenlos!',
			text3:
				' Verwandle deine Vorlesungsfolien in wissenschaftlich optimierte Karteikarten und entdecke eine noch effektivere und effizientere Art zu lernen.',
			tryFree: 'Gratis testen!',
		},
		newsLetterOptIn: {
			subscriptionSuccessful: 'Newsletter abonniert!',
			subscriptionText:
				'Du hast dich erfolgreich zu unserem Newsletter angemeldet und wirst bald von uns hören.',
			proceedButton: 'Weiter',
		},
		subscriptionShop: {
			title: 'Es wird Zeit für die AI Lernrevolution',
			subtitle:
				'Erhalte uneingeschränkten* Zugang zu Studyflash mit unseren flexiblen Abonnementen. Erstelle Karteikarten zum Fixpreis und spare dir unglaublich viel Zeit beim Lernen',
			month1: 'Monat',
			tos: '*Es gibt eine Fair-Usage-Policy, welche unverheltnismässiger Nutzung und somit Missbrauch entgegenwirken soll. Melde dich bei uns, wenn du das Limit erreichst.',
			free: {
				billed: 'Gratis testen!',
				title: 'KOSTENLOSE TESTVERSION',
				text: 'Das erste Set ist komplett gratis. Es wird keine Kreditkarte benötigt.',
				subscribeText: 'Gratis testen',
			},
			month: {
				billed: 'Monatlich abgerechnet',
				title: 'MONATSABO',
				text: 'Automatische Verlängerung. Jederzeit kündbar.',
				subscribeText: 'Monatsabo holen',
			},
			semester: {
				billed: 'Quartalsweise abgerechnet',
				title: 'SEMESTERABO',
				text: 'Automatische Verlängerung. Dreimonatige Bindung.',
				subscribeText: 'Semesterabo holen',
			},
			year: {
				billed: 'Jährlich abgerechnet',
				title: 'JAHRESABO',
				text: 'Automatische Verlängerung. Jährlich kündbar.',
				subscribeText: 'Jahresabo holen',
			},
			fairUsage1: 'Es gibt eine ',
			fairUsage2: 'Fair-Usage-Policy',
			fairUsage3:
				', welche unverheltnismässiger Nutzung und somit Missbrauch entgegenwirken soll. Melde dich bei uns, wenn du das Limit erreichst.',
			discountTip: 'Dieser Rabatt ist nur für die erste Abrechnungsperiode gültig!',
			validCode: 'Angewandter Rabattcode',
			invalidCode: 'Kein gültiger Rabattcode!',
			applyCode: 'Code anwenden',
			discountPlaceholder: 'Einen Rabattcode eingeben',
			referralDiscountApplied: 'Referral Code angewendet',
		},
		errorBoundary: {
			title: 'Es scheint, dass bei der Bearbeitung Ihrer Anfrage ein Fehler aufgetreten ist.',
			p11: 'Bitte versuchen Sie, ',
			p12: 'die Seite neu zu laden ',
			p13: 'oder ',
			p14: 'oder auszusingen.',
			p21: 'Sollte dies nicht helfen, können Sie sich jederzeit ',
			p22: 'an uns wenden.',
		},
		errorCodes: {
			general: {
				unhandledPromise: 'Fehlercode: UNPR01',
				createEmptyDeckFailed:
					'Ein Fehler ist aufgetreten beim Erstellen eines leeren Decks, bitte versuche es erneut! Fehlercode: POP03',
				undoCardDelete:
					'Ein Fehler ist aufgetreten beim Wiederherstellen der Karteikarte, bitte versuche es erneut! Fehlercode: POP01',
				undoDeckDelete:
					'Ein Fehler ist aufgetreten beim Wiederherstellen des Decks, bitte versuche es erneut! Fehlercode: POP02',
				feedbackFailed:
					'Ein Fehler ist aufgetreten beim Senden des Feedbacks, bitte versuche es erneut! Fehlercode: FE01',
				deckRatingFailed: "Es gab einen Fehler bei der Bewertung des Decks, bitte versuche es erneut! Fehlercode POP04",
				npsRatingFailed: "Es gab einen Fehler beim Speichern deiner Bewertung, bitte versuche es erneut! Fehlercode POP05",
			},
			dashboard: {
				cardCountingFailed:
					'Ein Fehler ist aufgetreten beim Zählen der Karten in deinen Decks, entschuldige die Unannehmlichkeiten. Fehlercode: DA01',
			},
			decks: {
				deleteFailed: "Ein Fehler ist aufgetreten beim Löschen des Decks, bitte versuche es erneut! Fehlercode: DE01",
				creatingCustomSessionFailed: "Ein Fehler ist aufgetreten beim Erstellen der klassischen Lern Session, bitte versuche es erneut! Fehlercode: DE02",
				insertingCardsIntoCustomSessionFailed: "Ein Fehler ist aufgetreten beim Erstellen deiner Lern Session, bitte versuche es erneut! Fehlercode: DE03",
				updateFailed: "Ein Fehler ist aufgetreten beim Aktualisieren des Decks, bitte versuche es erneut! Fehlercode: DE04",
				fetchCustomSessionsFailed: "Ein Fehler ist aufgetreten beim Abrufen deiner klassischen Lern-Sitzungen, bitte versuche es erneut! Fehlercode DE05"
			},
			deck: {
				createFlashcardFailed:
					'Ein Fehler ist aufgetreten beim Erstellen einer neuen Karteikarte! Fehlercode: DEOW01',
				createFlashcardFailedMobile:
					'Ein Fehler ist aufgetreten beim Erstellen einer neuen Karteikarte! Fehlercode: DEOW02',
				updateFailed1:
					'Ein Fehler ist aufgetreten beim Aktualisieren des Decks, bitte versuche es erneut! Fehlercode: DEOW03',
				updateFailed2:
					'Ein Fehler ist aufgetreten beim Aktualisieren des Decks, bitte versuche es erneut! Fehlercode: DEOW04',
				fetchFailed1:
					'Ein Fehler ist aufgetreten beim Abrufen deiner Karteikarten, bitte versuche es erneut! Fehlercode: DEOW05',
				fetchFailed2:
					'Ein Fehler ist aufgetreten beim Abrufen deiner Karteikarten, bitte versuche es erneut! Fehlercode: DEOW06',
				cardUpdateFailed1:
					'Ein Fehler ist aufgetreten beim Aktualisieren deiner Karteikarte, bitte versuche es erneut! Fehlercode: DEOW07',
				cardUpdateFailed2:
					'Ein Fehler ist aufgetreten beim Aktualisieren deiner Karteikarte, bitte versuche es erneut! Fehlercode: DEOW08',
				searchFailed1:
					'Ein Fehler ist aufgetreten bei der Suche nach deinen Karteikarten, bitte versuche es erneut! Fehlercode: DEOW09',
				searchFailed2:
					'Ein Fehler ist aufgetreten bei der Suche nach deinen Karteikarten, bitte versuche es erneut! Fehlercode: DEO10',
				deleteFailed1:
					'Ein Fehler ist aufgetreten beim Löschen deiner Karteikarte, bitte versuche es erneut! Fehlercode: DEOW11',
				deleteFailed2:
					'Ein Fehler ist aufgetreten beim Löschen deiner Karteikarte, bitte versuche es erneut! Fehlercode: DEOW12',
				countFailed:
					'Ein Fehler ist aufgetreten beim Zählen deiner Karteikarten, bitte versuche es erneut! Fehlercode: DEOW13',
			},
			customStudy: {
				deleteFailed: "Ein Fehler ist aufgetreten beim Löschen der klassischen Lern-Sitzungen. Fehlercode: CSS01",
				cardUpdateFailed1: "Ein Fehler ist aufgetreten beim Aktualisieren der Karteikarte, bitte versuche es erneut! Fehlercode: CSS02",
				cardUpdateFailed2: "Ein Fehler ist aufgetreten beim Aktualisieren der Karteikarte, bitte versuche es erneut! Fehlercode: CSS03",
				showPreviousFailed: "Ein Fehler ist aufgetreten beim Anzeigen der vorherigen Karteikarte, bitte versuche es erneut! Fehlercode: CSS04",
				saveChangesFailed: "Ein Fehler ist aufgetreten beim Speichern der Änderungen, bitte versuche es erneut. Fehlercode: CSS05",
				removeSessionFailed: "Ein Fehler ist aufgetreten beim Entfernen der abgeschlossenen Sitzung. Fehlercode: CSS06",
				initFailed1: "Ein Fehler ist aufgetreten beim Initialisieren der benutzerdefinierten Lernsitzung. Fehlercode: CSS07",
				initFailed2: "Ein Fehler ist aufgetreten beim Initialisieren der benutzerdefinierten Lernsitzung. Fehlercode: CSS08",
			},
			setting: {
				localeChangeFailed:
					'Ein Fehler ist aufgetreten beim Aktualisieren der Spracheinstellung, bitte versuche es erneut. Fehlercode: SET01',
				accountDeleteFailed:
					'Ein Fehler ist aufgetreten beim Löschen des Kontos, bitte versuche es erneut. Fehlercode: SET02',
				emailUpdateFailed:
					'Ein Fehler ist aufgetreten beim Aktualisieren der E-Mail-Adresse, bitte versuche es erneut. Fehlercode: SET03',
				emailUpdateFailedMobile:
					'Ein Fehler ist aufgetreten beim Aktualisieren der E-Mail-Adresse, bitte versuche es erneut. Fehlercode: SET04',
				subscriptionFetchFailed:
					'Ein Fehler ist aufgetreten beim Abrufen der Abonnementdetails, bitte versuche es erneut. Fehlercode: SET05',
				subscriptionUpdateFailed:
					'Ein Fehler ist aufgetreten beim Aktualisieren der Abonnementdetails, bitte versuche es erneut. Fehlercode: SET06',
				updateProfilePhotoFailed:
					'Ein Fehler ist aufgetreten beim Aktualisieren des Profilbild, bitte versuche es erneut. Fehlercode: SET07',
				manageSubscriptionsFailed:
					'Ein Fehler ist aufgetreten beim Verwalten deines Abonnements, bitte versuche es erneut. Fehlercode: SET08',
			},
			purchase: {
				redirectStripeFailed:
					'Ein Fehler ist aufgetreten beim Weiterleiten zu Stripe, bitte versuche es erneut. Fehlercode: SH01',
				referralCodeFailed:
					'Ein Fehler ist aufgetreten beim Überprüfen deines Empfehlungscodes, bitte versuche es erneut. Fehlercode: SH01',
				discountCodeFailed:
					'Ein Fehler ist aufgetreten beim Überprüfen deines Rabattcodes, bitte versuche es erneut. Fehlercode: SH02',
			},
			create: {
				fileUploadFailed:
					'Ein Fehler ist aufgetreten beim Hochladen deiner Datei, bitte versuche es erneut! Fehlercode: CR01',
				fileAccessFailed:
					'Ein Fehler ist aufgetreten beim Zugriff auf die hochgeladene Datei, bitte versuche es erneut! Fehlercode: CR02',
				createDeckFailed:
					'Ein Fehler ist aufgetreten beim Erstellen eines neuen Decks! Fehlercode: CR03',
				cardGenerationFailed:
					'Ein Fehler ist aufgetreten bei der Karteikartenerstellung, bitte versuche es erneut! Fehlercode: CR04',
				costCalculationFailed:
					'Ein Fehler ist aufgetreten bei der Berechnung der benötigten Tokens, bitte versuche es erneut! Fehlercode: CR05',
			},
			bin: {
				fetchingFailed:
					'Ein Fehler ist aufgetreten beim Abrufen der gelöschten Decks, bitte versuche es erneut! Fehlercode: DB01',
				restoringFailed:
					'Ein Fehler ist aufgetreten beim Wiederherstellen der Decks, bitte versuche es erneut! Fehlercode: DB02',
				checkingIsParentDeletedFailed:
					'Ein Fehler ist aufgetreten, bitte versuche es erneut! Fehlercode: DB03',
			},
			onboarding: {
				updateProfileFailed:
					'Ein Fehler ist aufgetreten beim Aktualisieren deines Profils, bitte versuche es erneut! Fehlercode: ON02',
				newsletterFailed:
					'Ein Fehler ist aufgetreten bei der Newsletter-Anmeldung. Fehlercode: ON01',
			},
			study: {
				fetchFailed:
					'Ein Fehler ist aufgetreten beim Abrufen der Karteikarten, bitte versuche es erneut! Fehlercode: ST02',
				progressSessionCreationFailed:
					'Ein Fehler ist aufgetreten beim Verfolgen des Fortschritts, aber du kannst weiterlernen. Fehlercode: ST01',
				flashcardHandlingFailed1:
					'Ein Fehler ist aufgetreten beim Verarbeiten der Karteikarte, bitte versuche es erneut! Fehlercode: ST05',
				flashcardHandlingFailed2:
					'Ein Fehler ist aufgetreten beim Verarbeiten der Karteikarte, bitte versuche es erneut! Fehlercode: ST06',
				saveChangesFailed:
					'Ein Fehler ist aufgetreten beim Speichern der Änderungen, bitte versuche es erneut! Fehlercode: ST03',
				progressSessionUpdateFailed:
					'Ein Fehler ist aufgetreten beim Erfassen deines Fortschritts, entschuldige die Unannehmlichkeiten. Fehlercode: ST04',
				cardDeletionFailed:
					'Ein Fehler ist aufgetreten beim Löschen der Karteikarten, bitte versuche es erneut! Fehlercode: ST07',
			},
			router: {
				accountAccessError: "There was an error with the authentication provider, sorry for the inconvenience, please just log in again!"
			}
		},
		deckFeedback: {
			welcome: "Hey, danke, dass du Studyflash getestet hast. Wir würden uns sehr über ein kurzes Feedback freuen, damit wir die Studyflash Erfahrung für uns alle verbessern können!",
			rateQuality: "Bitte bewerte die Gesamtqualität der erstellten Karteikarten.",
			unusable: "Unbrauchbar",
			excellent: "Einwandfrei",
			additionalFeedback: "Was hat dir an den Karteikarten (nicht) gefallen?",
			typeHere: "Dein Feedback..."
		},
		generalFeedback: {
			ratingGood: "Sehr wahrscheinlich",
			ratingBad: "Sehr unwahrscheinlich",
			question: "Wie wahrscheinlich ist es, dass du Studyflash einem Freund weiterempfehlen würdest?",
			why: "Wieso?",
			tellUsMore: "Erzähl uns ein bisschen mehr, damit wir Studyflash weiter verbessern können 🤝",
			yourAnswerHere: "Deine Antwort…",
			detractorText: "😢 Kannst du uns mehr über deine Bewertung sagen, damit wir uns verbessern können?"
		},
		tour: {
			fewTips: "Ein paar Tips für den Start mit Studyflash!",
			featureOs: "Hast du eine coole Idee? Klicke hier, um ein Feature vorzuschlagen und die Zukunft von Studyflash mitzugestalten",
			customStudySession: "Klicke hier, um deine Decks jederzeit unabhängig von deinem Lernfortschritt zu wiederholen.",
			next: "Weiter",
			back: "Zurück",
			gotIt: "Verstanden!"
		},
		beta: {
			try: "Lust es auszuprobieren?",
			optIn: "Los gehts!",
			optOut: "Vielleicht später",
			imageFlashcards: {
				title: "Endlich: Bilder in deinen Karteikarten!",
				description: "Teste jetzt unser neues Feature, das automatisch Bilder aus deinen Dateien in deine Karteikarten einfügt. So sparst du dir noch mehr Zeit als bisher! Als Top-User bist du unter den Ersten, die diese Funktion ausprobieren dürfen."
			}
		}
	},
}

export default de
